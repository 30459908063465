import React, {useState, useEffect} from 'react';
import {useParams, useLocation} from 'react-router-dom';
import Emoji from './Emoji.component';
import request from '../../utils/promiseRequest';
import examples from './examples.json';
import InputOverview from './InputOverview.component';
import moment from 'moment';
import 'moment/locale/da';

moment.locale('da');

const {selectorExamples, emojiDescriptions} = examples;

export default (props) => {
  const {pid} = useParams();
  const location = useLocation();
  const navigate = props.navigate;
  const beforeUnload = (e) => {
    if (!window.Cypress) {
      e.preventDefault();
      e.returnValue = '';
    }
  };
  const popState = () => {
    if (!window.Cypress) {
      const onConfirmationPage = location.pathname.includes('confirmation');
      setShowInputOverview(onConfirmationPage);
      if (onConfirmationPage) {
        navigate('/metabuggi/' + pid);
      }
    }
  };
  useEffect(() => {
    fetchBookData(pid);
    getEntriesForWork(pid);
    window.addEventListener('popstate', popState);
    window.addEventListener('beforeunload', beforeUnload);
    document.title = 'MetaBuggi';
    return () => {
      window.removeEventListener('popstate', popState);
      window.removeEventListener('beforeunload', beforeUnload);
      document.title = 'Metakompasset';
    };
  }, []);
  const [mainSelectorsValues, setMainSelectorsValues] = useState({
    'kort/lang': 0,
    'tekst/tegninger': 0,
    'let/svær': 0,
    'virkelig/fantasi': 0
  });
  const [examplesState, setExamplesState] = useState({}); // show hide mainselectors examples
  const [materialState, setMaterialState] = useState(null); // book data
  const [loading, setLoading] = useState(false);
  const [showInputOverview, setShowInputOverview] = useState(false);
  const [formatedTags, setFormatedTags] = useState(null);
  const [lastModified, setLastModified] = useState(null);
  const [editedByOthers, setEditedByOthers] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [entryCreated, setEntryCreated] = useState(false); // true if entry already exists in database
  const [emojiValues, setEmojiValues] = useState({
    // stemning
    rar: 0,
    sjov: 0,
    romantisk: 0,
    spændende: 0,
    trist: 0,
    uhyggelig: 0,
    tankevækkende: 0,
    // emne
    dyr: 0,
    sport: 0,
    venskaber: 0,
    'mit liv': 0,
    'ud i fremtiden': 0,
    'skæve karakterer': 0,
    'den store verden': 0,
    // genre
    fantasy: 0,
    gys: 0,
    eventyrlig: 0,
    action: 0,
    gaming: 0
  });
  const fetchBookData = async (workPid) => {
    try {
      setLoading(true);
      const result = await request({
        url: '/api/getMaterial',
        method: 'get',
        query: {id: workPid}
      });
      if (!result.body.error) {
        setMaterialState(result.body.material);
      }
    } catch (error) {
      // no error message needed
    } finally {
      setLoading(false);
    }
  };
  const getEntriesForWork = async (workPid) => {
    try {
      const result = await request({
        url: '/api/buggi/' + workPid,
        method: 'get'
      });
      const {user_id, edited_by} = result.body;
      setEditedByOthers(edited_by && user_id && user_id !== edited_by);
      setLastModified(result.body.modified);
      setEntryCreated(true);
      let fetchedEmojiValues = {...emojiValues};
      let fetchedMainSelectorsValues = {...mainSelectorsValues};
      let emojies = Object.keys(emojiValues);
      let tags = result.body.data.tags || [];

      tags.forEach((tag) => {
        if (emojies.includes(tag.name)) {
          fetchedEmojiValues[tag.name] = parseInt(tag.value, 10);
        } else {
          fetchedMainSelectorsValues[tag.name] = parseInt(tag.value, 10);
        }
      });
      setEmojiValues(fetchedEmojiValues);
      setMainSelectorsValues(fetchedMainSelectorsValues);
    } catch (error) {
      // no error message needed
      setEntryCreated(false);
    }
  };

  const createEntriesForWorkByPid = async (workPid, title, tags) => {
    return request({
      url: '/api/buggi',
      method: 'post',
      body: {
        material_id: workPid,
        title: title,
        data: {
          tags: tags
        }
      }
    });
    // setEntryCreated(true);
  };
  const updateEntriesForWorkByPid = async (workPid, title, tags) => {
    return request({
      url: '/api/buggi',
      method: 'put',
      body: {
        material_id: workPid,
        title: title,
        data: {
          tags: tags
        }
      }
    });
  };

  const renderSelector = ({labels = [], title, imageSrc, key}) => {
    const showExamples = examplesState[key];
    const examplesArray = selectorExamples[key]; // array of examples
    const cypressKey = key.replace('/', '-');
    return (
      <div className="slider-item row">
        <div className="col-12 col-sm-6">
          <div className="range-slider-header">
            {imageSrc && <img src={imageSrc} className="Emoji_small" alt={key + '_icon'} />}
            <h2>{title}:</h2>
            <h2 data-cy={'buggi-editor-' + cypressKey + '-value'} className="range-slider-value">
              {mainSelectorsValues[key]}
            </h2>
          </div>
          <div className="range-slider">
            <input
              className="range-slider-range"
              type="range"
              value={mainSelectorsValues[key]}
              min="1"
              max="5"
              step="1"
              onChange={(e) => setMainSelectorsValues({...mainSelectorsValues, [key]: parseInt(e.target.value, 10)})}
              data-cy={'buggi-editor-' + cypressKey + '-slider'}
            />
          </div>
          <div className="slider-labels">
            <p>{labels[0]}</p>
            <p>{labels[1]}</p>
          </div>
        </div>
        <div className="col-12 col-sm-6">
          <button
            data-cy={cypressKey + '-show-hide-slider-examples-btn'}
            className="show-examples-btn"
            onClick={() => setExamplesState({...examplesState, [key]: !showExamples})}
          >
            {showExamples ? 'Skjul eksempler' : 'Vis eksempler'}
          </button>
          <div className={showExamples ? 'example-container-show' : 'example-container-hide'}>
            {examplesArray.map((ex, index) => {
              return (
                <div className="example-item" data-cy={cypressKey + '-example-item' + index}>
                  <p>{ex.key}</p>
                  <p className="example-value">{ex.value}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderEmojiSelector = (emoji, index) => {
    const headline = index === 0 ? 'Stemning' : index === 7 ? 'Emne' : index === 14 ? 'Genre' : null;
    const cypressKey = emoji.replace(' ', '-');
    return (
      <React.Fragment>
        {headline && (
          <div className="emoji-group-header">
            <h1>{headline}</h1>
          </div>
        )}
        <div className="slider-item row">
          <div className="col-12 col-sm-6">
            <div className="range-slider-header">
              <Emoji name={emoji} size="small" />
              <h2>{emoji.charAt(0).toUpperCase() + emoji.slice(1)}:</h2>
              <h2 data-cy={'buggi-editor-' + cypressKey + '-value'} className="range-slider-value">
                {emojiValues[emoji]}
              </h2>
            </div>
            <div className="range-slider">
              <input
                className="range-slider-range"
                type="range"
                value={emojiValues[emoji]}
                min="0"
                max="5"
                step="1"
                onChange={(e) => {
                  let newEmojiValues = {...emojiValues};
                  newEmojiValues[emoji] = parseInt(e.target.value, 10);
                  setEmojiValues(newEmojiValues);
                }}
                data-cy={'buggi-editor-' + cypressKey + '-slider'}
              />
            </div>
          </div>

          <div className="emojiSelector-sub-header col-12 col-sm-6">
            {emojiDescriptions[emoji] && 'Stikord: ' + emojiDescriptions[emoji].join(', ') + '.'}
          </div>
        </div>
      </React.Fragment>
    );
  };
  const renderHeader = () => {
    return <h1 className="header-title">Metabuggi</h1>;
  };
  const goToDataConfirmPage = () => {
    const mainSelectorsFilled = Object.values(mainSelectorsValues).indexOf(0) >= 0 ? false : true;
    if (mainSelectorsFilled) {
      const keys = Object.keys(emojiValues);
      let tags = keys.map((key) => {
        return {
          name: key,
          value: emojiValues[key]
        };
      });
      tags = [
        {name: 'let/svær', value: mainSelectorsValues['let/svær']},
        {name: 'virkelig/fantasi', value: mainSelectorsValues['virkelig/fantasi']},
        {name: 'tekst/tegninger', value: mainSelectorsValues['tekst/tegninger']},
        {name: 'kort/lang', value: mainSelectorsValues['kort/lang']},
        ...tags
      ];
      setFormatedTags(tags);
      setShowInputOverview(true);
      navigate('/metabuggi/' + pid + '/confirmation');
    } else {
      setErrorMessage('*Du skal udfylde alle felterne i afsnittet Fortælleteknik.');
    }
  };

  const saveData = () => {
    if (entryCreated) {
      return updateEntriesForWorkByPid(materialState.pid, materialState.title, formatedTags);
    }
    return createEntriesForWorkByPid(materialState.pid, materialState.title, formatedTags);
  };

  const selectors = [
    {
      title: 'Længde',
      labels: ['Kort', 'Lang'],
      imageSrc: '/img/buggi/kort-lang.svg',
      key: 'kort/lang'
    },
    {
      title: 'Sværhedsgrad',
      labels: ['Let', 'Svær'],
      imageSrc: '/img/buggi/spytte.svg',
      key: 'let/svær'
    },
    {
      title: 'Illustrationer',
      labels: ['Tekst', 'Tegning'],
      imageSrc: '/img/buggi/ramme.svg',
      key: 'tekst/tegninger'
    },
    {
      title: 'Univers',
      labels: ['Virkelighed', 'Fantasi'],
      imageSrc: '/img/buggi/mermaid.png',
      key: 'virkelig/fantasi'
    }
  ];
  if (loading) {
    return (
      <div className="buggi-editor-container row">
        <h1>Henter data..</h1>
      </div>
    );
  }
  if (showInputOverview && materialState && formatedTags) {
    return (
      <InputOverview
        tags={formatedTags}
        goBack={() => {
          setShowInputOverview(false);
          navigate(-1);
        }}
        saveData={saveData}
        material={materialState}
        onDataSaved={() => navigate('/')}
        renderHeader={renderHeader}
      />
    );
  }
  if (materialState) {
    return (
      <div className="buggi-editor-container row">
        {renderHeader()}
        <div className="buggi-editor-book-info row">
          {materialState.image && <img src={materialState.image} alt={`Forside til ${materialState.title}`} />}
          <h1 data-cy="buggi-editor-material-title">{materialState.title}</h1>
          <h2> Af {materialState.creator} </h2>
          {lastModified && (
            <p>
              Sidst rettet: {moment(lastModified).format('Do MMMM  YYYY') + (editedByOthers ? ' - ' : '')}
              {editedByOthers && <span>rettet af redaktør</span>}
            </p>
          )}
          {}
        </div>
        <div className="selection-container row">
          <div className="emoji-group-header">
            <h1>Fortælleteknik</h1>
          </div>
          {selectors.map(renderSelector)}
          {Object.keys(emojiValues).map(renderEmojiSelector)}
        </div>
        <button data-cy="buggi-save-btn" className="buggi-submit-btn" onClick={goToDataConfirmPage}>
          Gem
        </button>
        {errorMessage && <p className="buggi-error-message">{errorMessage}</p>}
      </div>
    );
  }
  return (
    <div className="buggi-editor-container buggi-error-container">
      <Emoji name="trist" />
      <h1>Noget gik galt</h1>
    </div>
  );
};
