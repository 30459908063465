import React from 'react';
import State from './../../../store/state';
import MetaSummaryPage from './MetaSummaryPage.component';
import {getTaxonomyLeavesMap} from '../../../utils/taxonomy.utils';
import {Link} from 'react-router-dom';
import moment from 'moment';

export const EditedByEditor = () => {
  const txt = ' - Rettet af redaktør';
  return (
    <span data-cy="edited-by-editor" className="text-danger">
      {txt}
    </span>
  );
};

export default class MetaSummaryContainer extends React.Component {
  constructor(props) {
    super(props);

    const store = State.getState();
    const elementList = getTaxonomyLeavesMap(store.taxonomy);
    this.state = {
      elementList: elementList,
      material: store.material,
      submittingData: false
    };
    moment.updateLocale('da', {
      months: [
        'januar',
        'februar',
        'marts',
        'april',
        'maj',
        'juni',
        'juli',
        'august',
        'september',
        'oktober',
        'november',
        'december'
      ]
    });
  }

  getSelectedElements(root) {
    return this.props.selectedElements
      .filter((element) => this.state.elementList[element.id].parents[0] === root)
      .map((e) => this.state.elementList[e.id]);
  }

  getPages() {
    return Object.keys(this.props.taxonomy).map((title) => {
      if (title === 'fortælleteknik') {
        return this.getMainCharacterPages().concat(
          <MetaSummaryPage
            title={title}
            selectedElements={this.getSelectedElements(title)}
            comments={this.props.entry && this.props.entry.comments}
            key={title}
          />
        );
      }
      return (
        <MetaSummaryPage
          title={title}
          selectedElements={this.getSelectedElements(title)}
          comments={this.props.entry && this.props.entry.comments}
          key={title}
        />
      );
    });
  }

  getMainCharacterPages() {
    if (!this.props.mainCharacters) {
      return [];
    }
    return this.props.mainCharacters.map((mainCharacter) => {
      return (
        <MetaSummaryPage
          title={mainCharacter.systemName}
          selectedElements={mainCharacter.selectedElements.map((element) => this.state.elementList[element.id])}
          userGivenName={mainCharacter.name}
          key={mainCharacter.systemName}
        />
      );
    });
  }

  onClickAbort = () => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Siden bliver nulstillet. Vil du fortsætte?') === true) {
      State.setState({
        material: {
          pending: false
        },
        comments: {},
        currentIndex: 0,
        submittingData: false
      });
      window.location.replace('/');
    }
  };

  metaInputStyleRender(pages) {
    const onAddSentencePage = this.props.onAddSentencePage;
    return (
      <div className="meta--page--summary--container">
        <h2 className="meta--page--summary--headline title--thin">Opsummering</h2>
        <h4 className="meta--page--summary--material">
          {this.state.material.title}
          <br />
          <small>af {this.state.material.creator}</small>
        </h4>
        {this.state.material.subject && (
          <h4 className="meta--page--summary--material">
            Emneord
            <br />
            <small>{this.state.material.subject}</small>
          </h4>
        )}
        {!onAddSentencePage && <hr />}
        <div className="mb4">{pages}</div>
        <div className={this.props.onAddSentencePage ? 'hide-element' : ''}>
          <Link className="btn btn-success btn-block mb4" to="/prioritize" data-cy="meta-input-summary-approve-button">
            Godkend
          </Link>
          <button className="btn btn-link pl0" onClick={this.onClickAbort}>
            Annullér
          </button>
        </div>
      </div>
    );
  }

  editerStatus(entry) {
    const isLektier = !!entry.user.lektorId;

    if (isLektier) {
      return `Lektør [${entry.user.lektorId}]`;
    }

    return 'Borger';
  }

  selectMaterialStyleRender(pages) {
    return (
      <div className="meta--page--summary--container mb4" data-cy="meta-page-summary-container">
        <div className="row">
          <div className="col-md-9 col-sm-9 col-xs-12">
            <div className="row">
              <strong className="col-md-3 col-sm-3 col-xs-3">PID</strong>
              <span className="col-md-9 col-sm-9 col-xs-9">{this.props.entry.pid}</span>
            </div>
            <div className="row">
              <strong className="col-md-3 col-sm-3 col-xs-3">Dato</strong>
              <span className="col-md-9 col-sm-9 col-xs-9">
                {moment(this.props.entry.created).format('Do MMMM  YYYY')}
              </span>
            </div>
            <div className="row">
              <strong className="col-md-3 col-sm-3 col-xs-3">Redigeret af</strong>
              <span className="col-md-9 col-sm-9 col-xs-9">
                {this.editerStatus(this.props.entry)}
                {this.props.entry.editedBy && <EditedByEditor />}
              </span>
            </div>
            {this.state.material.subject && (
              <div className="row">
                <strong className="col-md-3 col-sm-3 col-xs-3">Emneord</strong>
                <span className="col-md-9 col-sm-9 col-xs-9">{this.state.material.subject}</span>
              </div>
            )}
          </div>
          <div className="col-md-3 col-sm-3 col-xs-12">
            <button
              className="btn btn-success btn-block mb4 width-fit-content float-right meta-edit-button"
              data-cy="meta-summary-edit-button"
              onClick={(e) => {
                e.preventDefault();

                const newState = {
                  ...this.props.entry
                };

                // Remove entry user from newState to prevent overriding loggedIn user
                delete newState.user;

                State.setState(newState);
                this.props.navigate('/meta');
              }}
            >
              REDIGER
            </button>
          </div>
        </div>

        <h2 className="meta--page--summary--headline title--thin">Opsummering</h2>
        <div className="mb4">{pages}</div>
      </div>
    );
  }

  render() {
    const pages = this.getPages();
    return this.props.selectMaterialStyle ? this.selectMaterialStyleRender(pages) : this.metaInputStyleRender(pages);
  }
}
