import React from 'react';
import Emoji from './Emoji.component';

export default ({show}) => {
  return (
    <div className={`modal ${show ? 'show-buggi-modal' : 'hide-buggi-modal'}`}>
      <div className="buggi-modal-main" data-cy="buggi-confirmation-modal">
        <img className="buggi-modal-logo" src="/img/BuggiLogo.svg" alt="Buggi logo" />
        <div className="buggi-modal-text-container">
          <div className="buggi-modal-subtitle">
            <p>Din indatering er gemt</p>
            <Emoji name="action" className="modal-emoji-small" />
          </div>

          <p>I morgen kan bogen findes på Buggi og skabe læseglæde hos en masse børn.</p>
          <p>Tak for hjælpen.</p>
        </div>
        <Emoji name="venskaber" className="modal-emoji" />
      </div>
    </div>
  );
};
