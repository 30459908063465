import React from 'react';
import PropTypes from 'prop-types';

const IntroPage = (props) => {
  return (
    <div className="meta--page">
      <h2>{props.introTitle}</h2>
      <div>{props.introText}</div>
    </div>
  );
};

IntroPage.propTypes = {
  introTitle: PropTypes.string.isRequired,
  introText: PropTypes.object.isRequired
};

export default IntroPage;
