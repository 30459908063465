import React from 'react';
import MetaInputPage from './MetaInputPage.component';
import MainCharacterPage from './maincharacters/MainCharacterPage.component';
import IntroPage from './IntroPage.component';
import MetaSidebarNavigation from './MetaSidebarNavigation.component';
import MetaInputNavigation from './MetaInputNavigation.component';
import MetaSummaryContainer from './summary/MetaSummaryContainer.component';
import {
  getTaxonomyGroups,
  getTaxonomyWithCharacters,
  getExcludedPaths,
  MAINCHARACTER_MERGE_PATH,
  getExcludedPagesForElement
} from '../../utils/taxonomy.utils';
import State from '../../store/state';
import {Link} from 'react-router-dom';

export default class MetaInputContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...State.getState()
    };
  }

  UNSAFE_componentWillMount() {
    State.subscribe(this.onGlobalStateChange);
  }

  componentWillUnmount() {
    State.unsubscribe(this.onGlobalStateChange);
  }

  onGlobalStateChange = ({
    selectedElements,
    mainCharacters,
    currentIndex,
    taxonomy,
    comments,
    material,
    loadingTaxonomy,
    mainTopic
  }) => {
    this.setState({
      selectedElements,
      mainCharacters,
      currentIndex,
      taxonomy,
      comments,
      material,
      loadingTaxonomy,
      mainTopic
    });
  };

  onSlide = (currentIndex) => {
    State.setState({currentIndex});
  };

  unselectElement(element, selectedElements) {
    selectedElements = selectedElements.filter((el) => el.id !== element.id);
    return selectedElements;
  }

  selectElement(element, selectedElements) {
    selectedElements = selectedElements.concat([element]);
    return selectedElements;
  }

  addCharacter = () => {
    const mainCharacters = [
      ...this.state.mainCharacters,
      {
        name: null,
        selectedElements: []
      }
    ];
    State.setState({mainCharacters});
  };

  removeCharacter = (idx) => {
    const mainCharacters = [...this.state.mainCharacters];
    mainCharacters.splice(idx, 1);
    State.setState({mainCharacters});
  };

  editCharacterName = (idx, name) => {
    const mainCharacters = [...this.state.mainCharacters];
    mainCharacters[idx] = {...mainCharacters[idx], name};
    State.setState({mainCharacters});
  };

  toggleElement = (element, parentRef, page, pages) => {
    // we either have to update selected elements of the book
    // or of a main character

    let selectedElements = page.mainCharacter ? page.mainCharacter.selectedElements : this.state.selectedElements;

    if (this.isElementSelected(element.id, selectedElements)) {
      // check if unselecting this element should unselect other elements
      // lower in the hieararchi
      if (element.page) {
        const ids = element.page.items.map((item) => item.id);
        selectedElements = selectedElements.filter((e) => !ids.includes(e.id) || e.parentRef !== element.id);
      }
      selectedElements = this.unselectElement(element, selectedElements);
    } else {
      if (element.excludedPaths) {
        // Check if selecting this element conflicts with other already selected elements
        // and if so, popup a confirm box asking for unselecting these
        const excludedPages = getExcludedPagesForElement(element, page, pages);
        const elementsToBeDeleted = excludedPages.reduce(
          (prev, current) => prev.concat(this.getPageSelectedElements(current)),
          []
        );
        const idsToBeDeletedMap = elementsToBeDeleted.reduce((prev, el) => {
          prev[el.id] = true;
          return prev;
        }, {});
        if (elementsToBeDeleted.length > 0) {
          if (
            // eslint-disable-next-line no-alert
            window.confirm(
              'Er du sikker? Dette vil fjerne følgende tags: ' + elementsToBeDeleted.map((el) => el.title).join(', ')
            )
          ) {
            selectedElements = selectedElements.filter((el) => !idsToBeDeletedMap[el.id]);
          } else {
            // abort
            return;
          }
        }
      }
      element.parentRef = parentRef;
      selectedElements = this.selectElement(element, selectedElements);
    }

    if (page.mainCharacter) {
      page.mainCharacter.selectedElements = selectedElements;
      State.setState({mainCharacters: this.state.mainCharacters});
    } else {
      State.setState({selectedElements});
    }
  };

  gotoPrevPage = () => {
    const prevIndex = State.getState().currentIndex - 1;
    State.setState({currentIndex: prevIndex});
  };

  gotoNextPage = () => {
    const nextIndex = State.getState().currentIndex + 1;
    State.setState({currentIndex: nextIndex});
  };

  gotoGeoSted = () => {
    State.setState({currentIndex: 2});
  };
  gotoGenrePage = () => {
    State.setState({currentIndex: 5});
  };
  gotoCharPage = () => {
    State.setState({currentIndex: 9});
  };

  previousPageButton = (previousPage) => {
    if (!previousPage) {
      return;
    }
    return (
      <div className="meta--page-left-btn text-center">
        <button onClick={this.gotoPrevPage} className="btn btn-default">
          <span className="glyphicon glyphicon-arrow-left" />
          Tilbage {/* til <b> {previousPage}</b> */}
        </button>
      </div>
    );
  };

  nextPageButton = (nextPage) => {
    if (!nextPage) {
      return (
        <div className="meta--page-right-btn text-center">
          <Link to="/prioritize">
            <button className="btn btn-default">
              Videre til <b> Godkend </b> <span className="glyphicon glyphicon-arrow-right" />
            </button>
          </Link>
        </div>
      );
    }

    return (
      <div className="meta--page-right-btn text-center">
        <button onClick={this.gotoNextPage} className="btn btn-default">
          Videre {/* til <b>{nextPage}</b> */}
          <span className="glyphicon glyphicon-arrow-right" />
        </button>
      </div>
    );
  };

  onNavigation = (path, pages) => {
    return pages.forEach((page, i) => {
      if (page.path.toLowerCase() === path.toLowerCase()) {
        State.setState({
          currentIndex: i,
          mainTopic: null
        });
        return;
      }
    });
  };

  onCommentChange(name, comment) {
    const comments = Object.assign({}, this.state.comments);
    if (comment === '') {
      delete comments[name];
    } else {
      comments[name] = comment;
    }
    State.setState({comments});
  }

  isElementSelected(id, selectedElements) {
    return selectedElements.filter((element) => element.id === id).length > 0;
  }

  getPageParentName(page) {
    const parents = page.path.split('.');
    return parents[parents.length - 2];
  }

  getPageLabel(page) {
    if (page) {
      const parent = this.getPageParentName(page);
      return (
        <span>
          <span className="capitalized">{parent}</span> <span className="capitalized">{page.title}</span>
        </span>
      );
    }
    return null;
  }

  getPageSelectedElements(page) {
    if (!page) {
      return [];
    }

    const selectedElements = page.mainCharacter ? page.mainCharacter.selectedElements : this.state.selectedElements;

    const selectedElementsMap = {};
    selectedElements.forEach((el) => (selectedElementsMap[el.id] = el));

    const getItems = (items) => {
      let res = [...items];
      items.forEach((el) => {
        if (el.page && el.page.items) {
          res = res.concat(getItems(el.page.items));
        }
      });
      return res;
    };
    const items = getItems(page.items);
    return items.filter((el) => selectedElementsMap[el.id]).map((el) => ({...el, ...selectedElementsMap[el.id]}));
  }

  renderFilterPage(pages, taxonomyWithCharacters, mainCharacters, excludedPaths) {
    let page = pages[this.state.currentIndex];
    let inputPage = null;

    const currentPageLabel = <span className="capitalized">Metakompas</span>;
    const excluded = excludedPaths[page.path];
    const currentPath = page.path;
    const nextPage = this.getPageLabel(pages[this.state.currentIndex + 1]);
    const prevPage = this.getPageLabel(pages[this.state.currentIndex - 1]);

    switch (page.type) {
      case 'MainCharacterPage':
        inputPage = (
          <MainCharacterPage
            mainCharacters={mainCharacters}
            onAddCharacter={this.addCharacter}
            onRemoveCharacter={this.removeCharacter}
            onEditCharacterName={this.editCharacterName}
            onCharacterClick={(name) =>
              this.onNavigation(
                MAINCHARACTER_MERGE_PATH + '.' + name + '.om hovedpersonen', // assuming that the first page title of a character is named 'om hovedpersonen'
                pages
              )
            }
          />
        );
        break;
      case 'PageIntro':
        inputPage = <IntroPage introTitle={page.title} introText={page.description(this.props)} />;
        break;
      default:
        inputPage = (
          <MetaInputPage
            items={page.items}
            title={page.title}
            key={page.path}
            toggleElement={(element, parentRef) => {
              this.toggleElement(element, parentRef, page, pages);
            }}
            selectedElements={this.getPageSelectedElements(page)}
            onCommentChange={(comment) => this.onCommentChange(currentPath, comment)}
            comment={this.state.comments[currentPath] || ''}
            excluded={excluded}
            pages={pages}
            path={page.path}
            orgPath={page.orgPath}
            gotoGeoSted={() => this.gotoGeoSted()}
            gotoGenrePage={() => this.gotoGenrePage()}
            gotoCharPage={() => this.gotoCharPage()}
          />
        );
    }

    return (
      <div className="meta--wrapper">
        <MetaInputNavigation
          currentIndex={this.state.currentIndex}
          currentPage={currentPageLabel}
          nextPage={nextPage}
          prevPage={prevPage}
          onNavigation={this.onSlide}
        />
        <div className="meta--container container">
          <div className="col-lg-3 col-md-3 col-sm-4">
            <MetaSidebarNavigation
              taxonomy={taxonomyWithCharacters}
              onNavigation={(path) => this.onNavigation(path, pages)}
              currentPath={currentPath}
              excludedPaths={excludedPaths}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-4">
            {inputPage}
            <div className="meta--subnav">
              {this.previousPageButton(prevPage)}
              {this.nextPageButton(nextPage)}
            </div>
          </div>
          <div className="row-eq-height col-lg-3 col-md-3 col-sm-4">
            <MetaSummaryContainer
              taxonomy={taxonomyWithCharacters}
              selectedElements={this.state.selectedElements}
              mainCharacters={this.state.mainCharacters}
              onComplete={() => this.onNavigation('prioritering', pages)}
              history={this.props.history}
              nextpage={!!nextPage}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (Object.keys(this.state.taxonomy).length === 0) {
      return null; // MetaInputContainer subscribes to state changes, so it will render again, whenever the taxonomy has loaded
    }
    // update main characters with system names
    this.state.mainCharacters.forEach((character, idx) => {
      character.systemName = 'Hovedperson ' + (idx + 1);
    });
    const taxonomyWithCharacters = getTaxonomyWithCharacters(this.state.taxonomy, this.state.mainCharacters);
    const taxonomyWithCharactersNoRefs = getTaxonomyWithCharacters(
      this.state.taxonomy,
      this.state.mainCharacters,
      false
    );

    const pages = getTaxonomyGroups(taxonomyWithCharacters, this.state.mainCharacters);
    const excludedPaths = getExcludedPaths(this.state.taxonomy, pages, this.state.selectedElements);
    if (pages.length === 0) {
      return <div className="container center">Henter taksonomi</div>;
    }
    return this.renderFilterPage(pages, taxonomyWithCharactersNoRefs, this.state.mainCharacters, excludedPaths);
  }
}

MetaInputContainer.propTypes = {};
