export const getTaxonomyLeaves = (taxonomy, parentStack = []) => {
  if (Array.isArray(taxonomy)) {
    return taxonomy.map((leaf) => {
      return Object.assign({}, leaf, {parents: [...parentStack]});
    });
  }
  let result = [];
  Object.entries(taxonomy).forEach(([key, value]) => {
    const tmp = getTaxonomyLeaves(value, [...parentStack, key]);
    result = result.concat(tmp);
  });
  return result;
};

export const getTaxonomyLeavesMap = (taxonomy) => {
  const res = {};
  getTaxonomyLeaves(taxonomy).forEach((leaf) => {
    res[leaf.id] = leaf;
  });
  return res;
};
