import request from 'superagent';

export default function promiseRequest({method, url, query = {}, body = {}}) {
  return new Promise((resolve, reject) => {
    request[method](url)
      .query(query)
      .send(body)
      .end((err, response) => {
        if (err) {
          reject(err);
        } else {
          resolve(response);
        }
      });
  });
}
