import React from 'react';
import PropTypes from 'prop-types';

const SentenceCheck = ({checkedLetter, id, onToggle, title, obsolete = false}) => {
  const checked = checkedLetter === 'A' || checkedLetter === 'B';

  return (
    <React.Fragment>
      <div className="sentence-list-item">
        <div className={'summary-sentences' + (obsolete ? ' obsolete' : '')}>
          <div className={(checked ? 'summary-selected' : 'summary-notSelected') + (obsolete ? ' obsolete' : '')}>
            {title}
          </div>
        </div>
        <div className="sentence-selector">
          <label
            className={checkedLetter === 'A' ? 'selected' : ''}
            checked={checked}
            id={id}
            onClick={onToggle}
            letter="A"
          >
            1{' '}
          </label>
          <label
            className={checkedLetter === 'B' ? 'selected' : ''}
            checked={checked}
            id={id}
            onClick={onToggle}
            letter="B"
          >
            2{' '}
          </label>
        </div>
      </div>
    </React.Fragment>
  );
};

SentenceCheck.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  onToggle: PropTypes.func,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  obsolete: PropTypes.bool
};

export default SentenceCheck;
