import React from 'react';
import State from '../../../store/state';

const encodeEntities = (line) => {
  return line.replace(/[\u00A0-\u9999<>&]/gim, function (i) {
    return '&#' + i.charCodeAt(0) + ';';
  });
};
const breakLine = (line, maxLineLength, lines) => {
  const spl = line.length > maxLineLength + 3 ? maxLineLength : line.length - 3;
  lines.push(line.substr(0, spl) + '-');
  return line.substr(spl);
};

/**
 * Split a line into lines, each at least minLineLength long, and at most maxLineLength long
 *
 * @param line
 * @param maxLineLength
 * @param maxLines
 * @returns {Array}
 */
const splitLine = (line, maxLineLength, maxLines = 6) => {
  const minLineLength = maxLineLength / 2;
  const words = line.split(' ');
  let w = '';
  const lines = [];
  for (let i = 0; i < words.length; i++) {
    if (w.length + words[i].length < maxLineLength) {
      w = (w + ' ' + words[i]).trim();
    } else {
      /* eslint-disable no-lonely-if */
      if (w.length >= minLineLength) {
        lines.push(w + ' ');
        w = words[i];
      } else {
        w = (w + ' ' + words[i]).trim();
        while (w.length > maxLineLength) {
          w = breakLine(w, maxLineLength, lines);
        }
      }
    }
    if (lines.length === maxLines) {
      const lastLine = lines[maxLines - 1];
      const end = lastLine.length > maxLineLength - 3 ? maxLineLength - 3 : lastLine.length;
      lines[maxLines - 1] = lastLine.substr(0, end) + '...';
      break;
    }
  }
  while (w.length > maxLineLength && lines.length < maxLines) {
    w = breakLine(w, maxLineLength, lines);
  }
  if (w.length && lines.length < maxLines) {
    lines.push(w);
  }
  return lines;
};
const generateSvg = (backgroundColor, title, creator) => {
  const titleLines = splitLine(title, 18, 6).map(encodeEntities);
  const tspanTitle = '<tspan x="50%">' + titleLines.join('</tspan><tspan x="50%" dy="1.1em">') + '</tspan>';
  const creatorLines = splitLine(creator, 18, 2).map(encodeEntities);
  const tspanCreator = '<tspan x="50%">' + creatorLines.join('</tspan><tspan x="50%" dy="1.1em">') + '</tspan>';
  const creatorPos = 6 + titleLines.length;

  return `<svg
    width="100%"
    height="100%"
    viewBox="0 0 200 300"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio = "xMinYMin meet"
    >
    <rect x="0" y="0" width="200" height="300" fill="${backgroundColor}" stroke-width="0" />
    <text x="50%" y="5em" fill="white" font-family="Verdana" font-weight="bold" font="bold 30px" class="title" alignment-baseline="middle" text-anchor="middle">
      ${tspanTitle}
    </text>
    <text x="50%" y="${creatorPos}.4em" fill="white" font-family="Verdana" class="creator" alignment-baseline="middle" text-anchor="middle">
      ${tspanCreator}
    </text>
    </svg>`;
};

const toColor = (id, colors = ['#f37362', '#a8c7b0', '#74a9ff', '#edb347']) => {
  let hash = 0;
  if (id.length === 0) {
    return hash;
  }
  for (let i = 0; i < id.length; i++) {
    hash = id.charCodeAt(i) + ((hash << 5) - hash);
  }
  hash = ((hash % colors.length) + colors.length) % colors.length;
  return colors[hash];
};

export const BookPreview = () => {
  const {material, editedSentences} = State.getState();
  const image = material.image
    ? material.image
    : 'data:image/svg+xml,' +
      encodeURIComponent(
        generateSvg(material.title ? toColor(material.title) : 'transparent', material.title, material.creator)
      );

  return (
    <div className="work-card ">
      <label>Visning fra Læsekompas</label>

      <div>
        <div aria-label="I en skov af sumak" className="book-cover ">
          <img alt={`Forside til ${material.title}`} src={image} />
        </div>
        <p className=" Text Text__body work-card__tax-description  ">
          {!editedSentences.aSentence && !editedSentences.bSentence && <span>Vælg sætninger</span>}
          {editedSentences.aSentence ? (
            <span>
              {editedSentences.aSentence}
              <br />
            </span>
          ) : (
            ''
          )}
          {editedSentences.bSentence ? (
            <span>
              {editedSentences.bSentence}
              <br />
            </span>
          ) : (
            ''
          )}
        </p>
      </div>
      <div className="whiteLine" />
    </div>
  );
};

export default BookPreview;
