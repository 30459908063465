const characters = {
  Æ: 1,
  æ: 2,
  Ø: 3,
  ø: 4,
  Å: 5,
  å: 6
};
export const danishCompare = (str1, str2) => {
  let char1;
  let char2;
  for (let i = 0; char1 === char2 && i < str1.length && i < str2.length; i++) {
    char1 = str1[i];
    char2 = str2[i];
  }
  let val1;
  let val2;
  if (char1 === char2) {
    val1 = str1.length;
    val2 = str2.length;
  } else {
    val1 = characters[char1] || 0;
    val2 = characters[char2] || 0;
    if (val1 === 0 && val2 === 0) {
      return char1.localeCompare(char2);
    }
  }
  const diff = val1 - val2;
  if (diff === 0) {
    return 0;
  }
  if (diff < 0) {
    return -1;
  }
  return 1;
};
