import React from 'react';
import MetaInputNavigation from '../MetaInputNavigation.component';
import SummaryPage from './SummaryPage.component';
import State from '../../../store/state';
import {getTaxonomyLeavesMap} from '../../../utils/taxonomy.utils';

export default class SummaryContainer extends React.Component {
  constructor() {
    super();
    State.subscribe(this.onGlobalStateChange);
    const {selectedElements, taxonomy, material, mainCharacters, sentences} = State.getState();
    const elementList = getTaxonomyLeavesMap(taxonomy);
    this.state = {
      selectedElements,
      taxonomy,
      material,
      elementList,
      mainCharacters,
      sentences
    };
  }

  componentWillUnmount() {
    State.unsubscribe(this.onGlobalStateChange);
  }

  onGlobalStateChange = ({selectedElements}) => {
    this.setState({selectedElements});
  };

  groupElementsByParent(elements) {
    const groups = {};
    elements
      .map((e) => {
        return {...this.state.elementList[e.id], ...e};
      })
      .forEach((element) => {
        let groupName;
        if (element.parents.includes('Miljø') || element.parents.includes('miljø')) {
          groupName = 'Miljø';
        } else if (element.parents.includes('handler om')) {
          groupName = 'Handler om';
        } else if (element.parents.includes('Stemning') || element.parents.includes('stemning')) {
          groupName = 'Stemning';
        } else {
          return;
        }
        if (!groups[groupName]) {
          groups[groupName] = {
            title: groupName,
            items: []
          };
        }
        groups[groupName].items.push(element);
      });

    return Object.entries(groups).map(([key, value]) => value);
  }

  getSelectedPrioElements(el) {
    let prio = el.filter((e) => e.score > 1).map((e) => this.state.elementList[e.id]);
    prio.forEach((obj) => (obj.prio = 'true'));

    let nonPrio = el.filter((e) => e.score < 2 || !e.score).map((e) => this.state.elementList[e.id]);

    return prio.concat(nonPrio);
  }

  render() {
    const {taxonomy, selectedElements, material, mainCharacters, sentences} = this.state;
    const taxonomyLeavesMap = getTaxonomyLeavesMap(taxonomy);

    return (
      <div className="meta--wrapper mb6">
        <MetaInputNavigation
          currentPageSpecial={'Metakompas'}
          prevPageSpecial={'Rediger indtastning'}
          onNavigation={() => this.props.navigate('/meta')}
        />

        <div className="meta--container container">
          <div className="col-lg-3 col-md-3 col-sm-4" />
          <div className="col-lg-6 col-md-6 col-sm-4">
            <div>
              <SummaryPage
                taxonomy={taxonomy}
                taxonomyLeavesMap={taxonomyLeavesMap}
                material={material}
                selectedElements={this.getSelectedPrioElements(selectedElements)}
                sentences={sentences.result}
                mainCharacters={mainCharacters.map((mainCharacter) => {
                  return {
                    ...mainCharacter,
                    selectedElements: mainCharacter.selectedElements.map((e) => this.state.elementList[e.id])
                  };
                })}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
