import React from 'react';
import State from '../../store/state';
import Store from '../../store/store';
export default class EmailPrompt extends React.Component {
  constructor() {
    super();
    this.state = {emailValue: ''};
  }
  componentDidMount() {
    State.subscribe(this.onGlobalStateChange.bind(this));
  }

  componentWillUnmount() {
    State.unsubscribe(this.onGlobalStateChange.bind(this));
  }

  onGlobalStateChange({user}) {
    if (JSON.stringify(user) !== JSON.stringify(this.state.user)) {
      this.setState({user: Object.assign({}, user)});
    }
  }
  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.emailValue.trim() === '') {
      return;
    }
    Store.assignEmail(this.state.emailValue);
  };
  onInputChange = (e) => {
    this.setState({emailValue: e.target.value});
  };
  render() {
    const user = State.getState().user;
    return (
      <div className={'input--form ' + this.props.className}>
        <h3 className="title">Kontaktoplysninger</h3>
        <p>
          Vi har brug for din emailadresse for at give dig adgang til dine tidligere indtastede værker og for at kunne
          kontakte dig ved ændringer eller fejl i systemet.
        </p>
        <form onSubmit={this.onSubmit}>
          <input
            type="Email"
            className="form-control mb2"
            id="email-input"
            placeholder="Email"
            onChange={this.onInputChange}
            value={this.state.emailValue}
            disabled={user.isAssigningEmail}
          />
          <button
            type="submit"
            className="btn btn-primary btn-block"
            disabled={user.isAssigningEmail}
            id="save-email-btn"
          >
            Gem email
          </button>
        </form>
      </div>
    );
  }
}
