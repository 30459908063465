import React from 'react';
import request from './utils/promiseRequest';
import moment from 'moment';

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      from: '2000-01-01',
      to: moment().format('YYYY-MM-DD'),
      lektorRows: {result: []},
      recordsPerPage: 10,
      orderBy: 'created',
      dir: 'desc',
      chosenLektorId: '',
      chosenPid: '',
      currentPage: 0
    };
  }

  componentDidMount() {
    this.getLektorData();
  }

  getQueryParams() {
    let f = this.state.from;
    let t = this.state.to;
    let todate = moment(t);
    todate.date(todate.date() + 1);

    let offset = this.state.currentPage * this.state.recordsPerPage;
    let rpp = this.state.recordsPerPage;
    let orderSortBy = this.state.orderBy + ' ' + this.state.dir;
    let chosenLektorId = this.state.chosenLektorId;

    return {
      from: f,
      to: todate.format('YYYY-MM-DD'),
      limit: rpp,
      offset: offset,
      orderBy: orderSortBy,
      lektorId: chosenLektorId
    };
  }

  renderExportLink() {
    const params = this.getQueryParams();
    return (
      <a
        href={`/api/accounting?returnType=csv&from=${params.from}&to=${params.to}&orderBy=${params.orderBy}&lektorId=${params.lektorId}&limit=1000000`}
      >
        Eksportér som CSV
      </a>
    );
  }

  async getLektorData() {
    try {
      this.setState({lektorRows: {fetching: true, error: false, result: []}});
      const result = await request({
        url: '/api/accounting',
        method: 'get',
        query: this.getQueryParams()
      });

      let res = result.body.result;

      if (res.length > 0) {
        this.setState({
          lektorRows: {fetching: false, error: false, result: res}
        });
      }
    } catch (error) {
      this.setState({lektorRows: {fetching: false, error, result: []}});
    }
  }

  onChangeDate = (e) => {
    let dir = e.target.id;
    if (dir === 'fromDate') {
      this.setState({from: e.target.value}, () => {
        this.getLektorData();
      });
    }
    if (dir === 'toDate') {
      this.setState({to: e.target.value}, () => {
        this.getLektorData();
      });
    }
  };

  onLektorClick = (lektorId) => {
    this.setState({chosenLektorId: lektorId}, () => {
      this.getLektorData();
    });
  };

  sortTable = (n) => {
    let dir = this.state.dir === 'asc' ? 'desc' : 'asc';
    if (this.state.orderBy !== n.target.id) {
      dir = 'desc';
    }
    this.setState({orderBy: n.target.id, dir: dir}, () => {
      this.getLektorData();
    });
  };

  prevPage = () => {
    let cp = this.state.currentPage - 1;
    this.setState({currentPage: cp}, () => {
      this.getLektorData();
    });
  };
  nextPage = () => {
    let cp = this.state.currentPage + 1;
    this.setState({currentPage: cp}, () => {
      this.getLektorData();
    });
  };

  clearFilters = () => {
    this.setState(
      {
        from: '2000-01-01',
        to: moment().format('YYYY-MM-DD'),
        lektorRows: {result: []},
        recordsPerPage: 10,
        orderBy: 'created',
        dir: 'desc',
        chosenLektorId: '',
        chosenPid: '',
        currentPage: 0
      },
      () => {
        this.getLektorData();
      }
    );
  };

  filterView = () => {
    return (
      <div
        style={{
          background: '#f3f3f3',
          width: '100%',
          height: '100%',
          border: 'solid 1px'
        }}
      >
        <div
          id="clearBtn"
          onClick={this.clearFilters}
          style={{
            margin: '7px',
            'text-align': 'right',
            cursor: 'pointer',
            textDecoration: 'underline'
          }}
        >
          ryd filteret
        </div>
        <table id="filterTable" style={{margin: '10px'}}>
          <tr>
            <td style={{width: '120px'}}> Valgt Datoer:</td>
            <td>
              fra: <b>{this.state.from}</b> til: <b>{this.state.to}</b>
            </td>
          </tr>
          <tr>
            <td> Retning:</td>
            <td>
              <b>{this.localize(this.state.dir)}</b>
            </td>
          </tr>
          {this.state.chosenLektorId && (
            <tr>
              <td> Valgt Lektor:</td>
              <td>
                <b>{this.state.chosenLektorId}</b>
              </td>
            </tr>
          )}
          {this.state.orderBy && (
            <tr>
              <td> Sorteret efter:</td>
              <td>
                <b> {this.localize(this.state.orderBy)} </b>
              </td>
            </tr>
          )}
          {this.state.chosenPid && (
            <tr>
              <td> Valgt faustnummer</td>
              <td>
                <b> {this.state.chosenPid} </b>
              </td>
            </tr>
          )}
        </table>
      </div>
    );
  };

  localize = (en) => {
    switch (en) {
      case 'lektorId':
        return 'Lektor Id';
      case 'pid':
        return 'Faustnummer';
      case 'title':
        return 'Bog Title';
      case 'created':
        return 'Oprettet';
      case 'asc':
        return 'Stigende';
      case 'desc':
        return 'Faldende';
      default:
        return en;
    }
  };

  getRows = () => {
    return this.state.lektorRows.result.map((material, index) => {
      return (
        <tr key={index}>
          <td
            style={{cursor: 'pointer', textDecoration: 'underline'}}
            id="lektorData"
            onClick={() => {
              this.onLektorClick(material.lektorId);
            }}
          >
            {material.lektorId}
          </td>
          <td
            style={{cursor: 'pointer', textDecoration: 'underline'}}
            id="pidData"
            onClick={() => {
              this.onIdClick(material.pid);
            }}
          >
            {material.pid.split('-')[1].replace('basis:', '')}
          </td>
          <td id="titleData">{material.title}</td>
          <td id="createdData">{material.created}</td>
        </tr>
      );
    });
  };

  showArrow = (id) => {
    if (this.state.orderBy === id) {
      return <i className={'accounting-arrow-' + this.state.dir}> </i>;
    }
  };

  render() {
    let resLen = this.state.lektorRows.result.length === 10;
    let cp = this.state.currentPage !== 0;
    const pageTitle = 'Lektører';
    return (
      <div className="accounting-app" style={{margin: '50px'}}>
        <h4>{pageTitle}</h4>
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 50
            }}
          >
            <div>{this.filterView()}</div>
            <div>
              fra:&nbsp;
              <input type="date" id="fromDate" onChange={this.onChangeDate} value={this.state.from} />
              &nbsp;>>&nbsp; til:&nbsp;
              <input type="date" id="toDate" onChange={this.onChangeDate} value={this.state.to} />
            </div>
            <div>{this.renderExportLink()}</div>
          </div>

          <div>
            <table id="lektorTable" className="table table-hover">
              <thead style={{cursor: 'pointer'}}>
                <tr>
                  <th onClick={this.sortTable} id={'lektorId'}>
                    Lektør Id &nbsp;&nbsp; {this.showArrow('lektorId')}
                  </th>
                  <th onClick={this.sortTable} id={'pid'}>
                    Faustnummer &nbsp;&nbsp; {this.showArrow('pid')}
                  </th>
                  <th onClick={this.sortTable} id={'title'}>
                    Bogtitel &nbsp;&nbsp; {this.showArrow('title')}
                  </th>
                  <th onClick={this.sortTable} id={'created'}>
                    Oprettet &nbsp;&nbsp; {this.showArrow('created')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.getRows()}
                <tr style={{cursor: 'pointer', textDecoration: 'underline'}}>
                  {cp && (
                    <th onClick={this.prevPage} id="btn_prev">
                      Previous
                    </th>
                  )}
                  {resLen && (
                    <th onClick={this.nextPage} id="btn_next">
                      Next
                    </th>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <a>Side {this.state.currentPage + 1} </a>
          </div>
        </div>
      </div>
    );
  }
}
