const LOCAL_STORAGE_KEY = 'metakompasset';

class State {
  constructor() {
    const defaultState = {
      user: {
        isAuthenticated: false,
        authenticationError: {},
        email: '',
        isPending: true
      },
      currentIndex: 0,
      loadingTaxonomy: false,
      submittingData: false,
      selectedElements: [],
      mainCharacters: [],
      sentences: {result: []},
      editedSentences: {
        aSentence: '',
        bSentence: ''
      },
      comments: {},
      material: {
        isPending: false
      },
      reviewedMaterial: [],
      dataEntries: [],
      buggiEntries: []
    };

    this.state = Object.assign(defaultState, this.getLocalStorage());
    this.taxonomy = {};
    this.metadata = [];
    this.metadataAll = [];
    this.subscribers = [];

    this.setState(this.state);
  }

  getLocalStorage() {
    const storageString = sessionStorage.getItem(LOCAL_STORAGE_KEY);
    return (storageString && JSON.parse(storageString)) || {};
  }

  setLocalStorage(state) {
    const stateCopy = {...state};
    delete stateCopy.user;
    sessionStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(stateCopy));
  }

  setState(newState) {
    this.state = Object.assign({}, this.state, newState);
    delete this.state.taxonomy; // Save space in sessionStorage by ...
    delete this.state.metadata; // (and here)
    delete this.state.metadataAll; // (and here)
    this.setLocalStorage(this.state);
    if (newState.taxonomy) {
      this.taxonomy = {...newState.taxonomy}; // ... moving data to the State Class
    }
    if (newState.metadata) {
      this.metadata = newState.metadata; // ... (and here)
    }
    if (newState.metadataAll) {
      this.metadataAll = newState.metadataAll; // ... (and here)
    }
    this.onChange();
  }

  getState() {
    return {
      taxonomy: {...this.taxonomy},
      metadata: this.metadata,
      metadataAll: this.metadataAll,
      ...this.state
    };
  }

  onChange() {
    const newState = this.getState();
    this.subscribers.forEach((subscriber) => subscriber(newState));
  }

  subscribe(subscriber) {
    this.subscribers.push(subscriber);
  }

  unsubscribe(unsubscriber) {
    this.subscribers = this.subscribers.filter((subscriber) => subscriber !== unsubscriber);
  }
}

export default new State();
