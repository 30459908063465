import React from 'react';
import PropTypes from 'prop-types';

export default class MetaInputNavigation extends React.Component {
  nextLink() {
    const nextIndex = this.props.currentIndex + 1;
    if (this.props.nextPage) {
      return (
        <h4 className="meta--nav meta--next btn" onClick={() => this.props.onNavigation(nextIndex)}>
          {this.props.nextPage} <span className="glyphicon glyphicon-arrow-right" />
        </h4>
      );
    }
  }

  prevLink() {
    const prevIndex = this.props.currentIndex - 1;
    let prevPage = this.props.prevPage;
    if (this.props.prevPageSpecial) {
      prevPage = this.props.prevPageSpecial;
    }
    if (prevPage) {
      return (
        <h4 className="meta--nav meta--prev btn" onClick={() => this.props.onNavigation(prevIndex)}>
          <span className="glyphicon glyphicon-arrow-left" /> {prevPage}
        </h4>
      );
    }
  }

  render() {
    let currentPage = this.props.currentPage;
    if (this.props.currentPageSpecial) {
      currentPage = this.props.currentPageSpecial;
    }

    return (
      <div className="meta--header">
        <div className="meta--header-fixed" style={{display: 'inline-flex'}}>
          <div className="container">
            <div>
              <h1 className="title--thin text-center">{currentPage}</h1>
            </div>
            {currentPage === 'Metakompas' && <div className="meta--actions">{this.prevLink()} </div>}
          </div>
          <div>
            <form action="/api/auth/logout">
              <button type="submit" className="btn btn-link pl0" id="logout-btn">
                Log ud
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

MetaInputNavigation.propTypes = {
  // currentPageSpecial and prevPageSpecial is just to avoid getting a warning for
  // passing a label as a string from the summary sequence (instead of as an object).

  currentPage: PropTypes.object,
  currentPageSpecial: PropTypes.string,
  prevPage: PropTypes.object,
  prevPageSpecial: PropTypes.string,
  nextPage: PropTypes.object,
  currentIndex: PropTypes.number,
  onNavigation: PropTypes.func.isRequired
};
