import React from 'react';
import State from '../store/state';

export default () => {
  const user = State.getState().user;
  if (!user.isAuthenticated) {
    window.location.href = '/login';
    return null;
  }
  return (
    <h2 className="title--thin text-center mt5" data-cy="get-email-info-line">
      {user.email && user.email !== ''
        ? `Din email adresse er: ${user.email}`
        : 'Der er ikke registreret en email adresse på denne konto'}
    </h2>
  );
};
