import React, {useEffect} from 'react';
import SentenceCheck from './SentenceCheck.js';
import PropTypes from 'prop-types';
import State from '../../../store/state';

const isObsolete = (tags, selected) => tags.reduce((acc, tag) => acc || !selected.includes(tag), false);

export const areAnyObsolete = (sentences, selectedElements) => {
  const selected = selectedElements.map((element) => element.id);
  return sentences.reduce(
    (acc, sentence) => acc || (sentence.valid && !!sentence.checked && isObsolete(sentence.tags, selected)),
    false
  );
};

const AddSentencesPage = ({sentences, selectedElements, onToggle, tagIdToTagName, noSentencesGenerated}) => {
  const selected = selectedElements.map((element) => element.id);
  // const [aSentenceEdited, setAsentenceEdited] = useState("");
  // const [bSentenceEdited, setBsentenceEdited] = useState("");

  const trim = (str) => str.replace(/^\s+|\s+$/g, '');

  const uncheckSentence = (checkedLetter) => {
    let stateSentences = State.getState().sentences;
    if (stateSentences.fetching === false) {
      stateSentences.result.forEach((s) => {
        if (s.checkedLetter === checkedLetter) {
          s.checked = false;
          window.location.reload(false);
        }
      });
      State.setState({sentences: {...stateSentences}});
    }
  };

  const ObsoleteWarning = () => (
    <div className="addSentence--obsolete-sentence" data-cy="addSentence-obsolete-sentence">
      Sætningen indeholder fjernede tags.
      <br />
      Vælg en anden sætning.
    </div>
  );

  const setAsentenceEdited = (value) => {
    if (trim(value) === '') {
      uncheckSentence('A');
    }
    let editedSentences = State.getState().editedSentences;
    State.setState({editedSentences: {...editedSentences, aSentence: value}});
  };
  const setBsentenceEdited = (value) => {
    if (trim(value) === '') {
      uncheckSentence('B');
    }
    let editedSentences = State.getState().editedSentences;
    State.setState({editedSentences: {...editedSentences, bSentence: value}});
  };
  useEffect(() => {
    sentences.forEach((s) => {
      if (s.checkedLetter === 'A') {
        setAsentenceEdited(s.sentence);
      } else if (s.checkedLetter === 'B') {
        setBsentenceEdited(s.sentence);
      }
    });
  }, [0]);
  useEffect(() => {
    sentences.forEach((s) => {
      if (s.checkedLetter === 'A') {
        setAsentenceEdited(s.sentence);
      } else if (s.checkedLetter === 'B') {
        setBsentenceEdited(s.sentence);
      }
    });
  }, [sentences]);
  const totalChecks = sentences && sentences.filter((s) => s.checked);
  const sentenceString = sentences.map((s, id) => {
    if (s.valid) {
      return (
        <React.Fragment key={id}>
          <SentenceCheck
            id={id}
            title={s.originalSentence || s.sentence}
            checkedLetter={s.checkedLetter}
            onToggle={onToggle}
            disabled={totalChecks.length > 1}
            obsolete={s.checked && isObsolete(s.tags, selected)}
            letter={s.letter}
          />
        </React.Fragment>
      );
    }
    return <React.Fragment key={id} />;
  });
  let aSentence = {sentence: null, tags: []};
  let bSentence = {sentence: null, tags: []};
  sentences.forEach((s) => {
    if (s.checkedLetter === 'A') {
      aSentence = s;
    } else if (s.checkedLetter === 'B') {
      bSentence = s;
    }
  });

  const aSentenceEdited = State.getState().editedSentences.aSentence;
  const bSentenceEdited = State.getState().editedSentences.bSentence;
  if (!aSentence.sentence && aSentenceEdited.length > 0) {
    setAsentenceEdited('');
  }
  if (!bSentence.sentence && bSentenceEdited.length > 0) {
    setBsentenceEdited('');
  }

  return (
    <React.Fragment>
      <div className="add-sentences-container">
        <div className="selected-senteces">
          <div className="sentence-input-item">
            <div className="label-container ">
              <label>Sætning 1</label>
              <label>{(aSentenceEdited || '').length} tegn</label>
            </div>

            <input
              placeholder="Vælg en sætning nedenfor"
              value={aSentenceEdited}
              onChange={(e) => setAsentenceEdited(e.target.value)}
              disabled={aSentence && aSentence.tags.length === 0 && aSentenceEdited.length === 0}
            />
            <div className="tag-container">
              <p>Tags: </p>{' '}
              {aSentence && isObsolete(aSentence.tags, selected) ? (
                <ObsoleteWarning />
              ) : (
                aSentence.tags.map((tag) => <label key={tag}>{tagIdToTagName(tag)}</label>)
              )}
            </div>
          </div>
          <div className="sentence-input-item">
            <div className="label-container ">
              <label>Sætning 2</label>
              <label>{(bSentenceEdited || '').length} tegn</label>
            </div>

            <input
              placeholder="Vælg en sætning nedenfor"
              value={bSentenceEdited}
              onChange={(e) => setBsentenceEdited(e.target.value)}
              disabled={bSentence && bSentence.tags.length === 0 && bSentenceEdited.length === 0}
            />
            <div className="tag-container">
              <p>Tags: </p>{' '}
              {bSentence && isObsolete(bSentence.tags, selected) ? (
                <ObsoleteWarning />
              ) : (
                bSentence.tags.map((tag) => <label key={tag}>{tagIdToTagName(tag)}</label>)
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={`${noSentencesGenerated ? 'no-sentences-table ' : ''} table-responsive`}>
        <div className="sentence-list-header">
          <label>Forslag</label>
          <label>Vælg som sætning</label>
        </div>

        {noSentencesGenerated ? (
          <span className="no-sentences-message">
            Gå tilbage og tilføj flere ord for at få forslag til sætninger her.
          </span>
        ) : (
          sentenceString
        )}
      </div>
    </React.Fragment>
  );
};

AddSentencesPage.propTypes = {
  sentences: PropTypes.array,
  selectedElements: PropTypes.array,
  onToggle: PropTypes.func
};

export default AddSentencesPage;
