import React from 'react';
import PropTypes from 'prop-types';

const getCommentsForThisEntry = (comments, title) => {
  if (!comments) {
    return [];
  }
  const result = [];
  for (const [key, value] of Object.entries(comments)) {
    if (RegExp('^' + title).test(key)) {
      result.push(value);
    }
  }
  return result;
};

export default class MetaSummaryPage extends React.Component {
  render() {
    let konfliktElements = [];
    let nonKonfliktElements = [];
    let harKonflikt = false;
    for (let i = 0; i < this.props.selectedElements.length; i++) {
      if (!this.props.selectedElements[i]) {
        return;
      }
      const t = this.props.selectedElements[i].title.replace('¤', '');
      const p = this.props.selectedElements[i].parents[2];

      if (p === 'hovedpersonens konflikt') {
        konfliktElements.push(t);
        harKonflikt = true;
      } else {
        nonKonfliktElements.push(t);
      }
    }

    const commentsForThisEntry = getCommentsForThisEntry(this.props.comments, this.props.title);
    return (
      <div className="summary--page">
        <h4 className="title--thin mb0 capTitle">
          {this.props.title}
          {this.props.userGivenName && <small> ({this.props.userGivenName})</small>}
        </h4>
        <div>
          <small>{nonKonfliktElements.join(', ')}</small>

          {harKonflikt && (
            <div>
              {' '}
              <small>
                {' '}
                <b style={{fontWeight: '500'}}>I konflikt med: </b>
                {konfliktElements.join(', ')}
              </small>
            </div>
          )}
        </div>
        {commentsForThisEntry.length > 0 && (
          <div>
            <small>
              <strong className="summary-page--suggestion">Forslag:&nbsp;</strong>
              {commentsForThisEntry.join(', ')}
            </small>
          </div>
        )}
      </div>
    );
  }
}

MetaSummaryPage.propTypes = {
  title: PropTypes.string.isRequired,
  selectedElements: PropTypes.array.isRequired
};
