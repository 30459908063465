import React from 'react';
import PropTypes from 'prop-types';

const Element = ({title, score, onToggle, group}) => {
  title = title.replace('¤', '');
  let disabled = group.filter((e) => e.score === 2).length > 2 && score !== 2 ? 'disabled' : '';
  return (
    <label className="priority">
      <input type="checkbox" checked={score === 2} onChange={onToggle} disabled={disabled} /> &nbsp;
      <div className={score === 2 ? 'summary-selected' : 'summary-notSelected'}> {title} </div>
    </label>
  );
};

export default class PrioritizePage extends React.Component {
  render() {
    const {groups, onToggleElement} = this.props;

    return (
      <div className="mb4">
        <h3>Prioritering</h3>
        <p>Ud af de ord, du har valgt, hvilke ord beskriver så bogen bedst? Vælg maksimalt tre ord pr. kategori.</p>

        {groups.map((group) => (
          <div key={group.title}>
            <h3 className="title--thin mb0">{group.title} </h3>

            {group.items.map((element) => (
              <Element onToggle={() => onToggleElement(element)} key={element.id} {...element} group={group.items} />
            ))}
          </div>
        ))}
      </div>
    );
  }
}

PrioritizePage.propTypes = {
  groups: PropTypes.array,
  onToggleElement: PropTypes.func
};
