import React, {useEffect, useState} from 'react';
import {BrowserRouter, Routes, Route, Navigate, useNavigate} from 'react-router-dom';
import LoginContainer from './components/login/LoginContainer.component';
import State from './store/state';
import Store from './store/store';
import BuggiEditor from './components/buggi/Editor.component';
import MaterialContainer from './components/material/MaterialContainer.component';
import MetaInputContainer from './components/meta/MetaInputContainer.component';
import PrioritizeContainer from './components/meta/summaryconfirmation/Prioritize.container';
import GetEmail from './components/GetEmail.component';
import AddSentencesContainer from './components/meta/summaryconfirmation/AddSentences.container';
import ConfirmationContainer from './components/meta/summaryconfirmation/Summary.container';
import ThankyouContainer from './components/thankyou/ThankyouContainer.component';
import Footer from './components/Footer.component.js';

const ProtectedRoute = ({children, ...rest}) => {
  const navigate = useNavigate();
  if (!State.getState().user.isAuthenticated || State.getState().user.email === null) {
    return <Navigate to="/login" {...rest} />;
  }
  if (rest.requireMaterial && !State.getState().material.pid) {
    return <Navigate to="/" {...rest} />;
  }
  return React.cloneElement(children, {navigate, ...rest});
};

const ProtectedLoginRoute = ({children}) => {
  if (State.getState().user.isAuthenticated && State.getState().user.email !== null) {
    return <Navigate to="/" />;
  }
  return children;
};

const App = () => {
  const [state, setState] = useState({user: Object.assign({}, State.getState().user)});
  useEffect(() => {
    State.subscribe(onGlobalStateChange);
    Store.getStatus();
    Store.getTaxonomy();
    return () => {
      State.unsubscribe(onGlobalStateChange);
    };
  });

  const onGlobalStateChange = ({user}) => {
    if (JSON.stringify(user) !== JSON.stringify(state.user)) {
      setState({user: Object.assign({}, user)});
    }
  };

  if (state.user.isPending) {
    return null;
  }

  return (
    <BrowserRouter>
      <div>
        <div>
          <Routes>
            <Route
              exact
              path={'/'}
              element={
                <ProtectedRoute>
                  <MaterialContainer />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={'/login'}
              element={
                <ProtectedLoginRoute>
                  <LoginContainer user={State.getState().user} />
                </ProtectedLoginRoute>
              }
            />
            <Route
              path={'/metabuggi/:pid'}
              element={
                <ProtectedRoute>
                  <BuggiEditor />
                </ProtectedRoute>
              }
            />
            <Route
              path={'/metabuggi/:pid/confirmation'}
              element={
                <ProtectedRoute>
                  <BuggiEditor />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={'/meta'}
              requireMaterial={true}
              element={
                <ProtectedRoute>
                  <MetaInputContainer />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={'/confirmation'}
              requireMaterial={true}
              element={
                <ProtectedRoute>
                  <ConfirmationContainer />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={'/sentences'}
              requireMaterial={true}
              element={
                <ProtectedRoute>
                  <AddSentencesContainer />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={'/prioritize'}
              requireMaterial={true}
              element={
                <ProtectedRoute>
                  <PrioritizeContainer />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={'/getemail'}
              requireMaterial={true}
              element={
                <ProtectedRoute>
                  <GetEmail />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={'/thankyou'}
              element={
                <ProtectedRoute>
                  <ThankyouContainer />
                </ProtectedRoute>
              }
            />
          </Routes>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;
