import React from 'react';

const Endpoint = ({path, name, isCurrentPath, onNavigation, isExcluded}) => (
  <span
    className={`navigation--title ${isCurrentPath ? 'b' : ''}`}
    data-cy="navigation-title"
    style={{color: isExcluded && 'grey'}}
    href={`#${path}`}
    onClick={() => onNavigation(path)}
  >
    {name === 'genre' ? 'genre og form' : name}
  </span>
);

export default class MetaSidebarNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openGroups: {}
    };
  }

  toggleGroup(path) {
    this.setState({
      openGroups: {
        ...this.state.openGroups,
        [path]: !this.state.openGroups[path]
      }
    });
  }

  componentDidUpdate(nextProps) {
    if (this.props.currentPath !== nextProps.currentPath) {
      this.setState({openGroups: {}});
    }
  }

  renderLevel(navigation, parents = '', level = 1) {
    return (
      <ul className={`level-${level}`}>
        {Object.keys(navigation).map((element, i) => {
          let elementExt = element;
          if (level === 3) {
            elementExt = 'Hovedperson ' + Number(i + 1);
          }
          const path = parents ? `${parents}.${element}` : element;
          const hasCurrentPage = this.props.currentPath.indexOf(path) === 0;
          const isCurrentPath = this.props.currentPath === path;
          const groupIsOpen = hasCurrentPage || this.state.openGroups[path];
          const isExcluded = !!this.props.excludedPaths[path];
          if (Array.isArray(navigation[element])) {
            return (
              <li key={element}>
                <Endpoint
                  isCurrentPath={isCurrentPath}
                  isExcluded={isExcluded}
                  path={path}
                  name={element}
                  onNavigation={this.props.onNavigation}
                />
              </li>
            );
          }
          return (
            <li
              key={element}
              className={`${hasCurrentPage ? 'has-currentPage' : ''} ${groupIsOpen ? 'is-openGroup' : ''}`}
            >
              <span
                className={`navigation--title ${isCurrentPath ? 'b' : ''}`}
                data-cy="navigation-title"
                onClick={() => {
                  this.toggleGroup(path);
                  this.props.onNavigation(path);
                }}
              >
                {elementExt}{' '}
                <i className={`glyphicon ${groupIsOpen ? 'glyphicon-triangle-bottom' : 'glyphicon-triangle-left'}`} />
              </span>{' '}
              {this.renderLevel(navigation[element], path, level + 1)}
            </li>
          );
        })}
      </ul>
    );
  }

  render() {
    return <div className="navigation--sidebar">{this.renderLevel(this.props.taxonomy)}</div>;
  }
}
