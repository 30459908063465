import React from 'react';
import {Link} from 'react-router-dom';

export const PreviousPageButton = ({navigateTo, onClick, ...props}) => (
  <div className="meta--page-left-btn no-left-margin text-center">
    <Link to={navigateTo} onClick={onClick} {...props}>
      <button className="btn btn-default" data-cy="previous-page-button">
        <span className="glyphicon glyphicon-arrow-left" /> Tilbage
      </button>
    </Link>
  </div>
);

export const NextPageButton = ({navigateTo, onClick, ...props}) => (
  <div className="meta--page-right-btn no-right-margin text-center">
    <Link to={navigateTo} onClick={onClick} {...props}>
      <button className="btn btn-default" data-cy="next-page-button">
        Videre <span className="glyphicon glyphicon-arrow-right" />
      </button>
    </Link>
  </div>
);
