import {get, set, unset} from 'lodash';
import InternalData from '../InternalData.js';
import {danishCompare} from './localesort';

export const getTaxonomyLeaves = (taxonomy, parentStack = []) => {
  if (Array.isArray(taxonomy)) {
    return taxonomy.map((leaf) => {
      return Object.assign({}, leaf, {parents: [...parentStack]});
    });
  }
  let result = [];
  Object.entries(taxonomy).forEach(([key, value]) => {
    const tmp = getTaxonomyLeaves(value, [...parentStack, key]);
    result = result.concat(tmp);
  });
  return result;
};

export const getTaxonomyLeavesMap = (taxonomy) => {
  const res = {};
  getTaxonomyLeaves(taxonomy).forEach((leaf) => {
    res[leaf.id] = leaf;
  });
  return res;
};

export const alphabetize = (elements) => {
  elements.sort((a, b) => danishCompare(a.title, b.title));
  return elements;
};
const createTaxonomyGroups = (taxonomy, parentStack = []) => {
  let pages = [];
  Object.entries(taxonomy).forEach(([title, group]) => {
    if (Array.isArray(group)) {
      // const items = alphabetize([...group]);

      pages.push({
        path: [...parentStack, title].join('.'),
        title: title,
        items: [...group]
      });
    } else {
      pages = pages.concat(createTaxonomyGroups(group, [...parentStack, title]));
    }
  });
  return pages;
};

const ORG_MAINCHARACTER_PATH = 'handling.hovedperson(er) - beskrivelse';
export const MAINCHARACTER_MERGE_PATH = 'handling.hovedperson(er) - beskrivelse';

export const getTaxonomyGroups = (taxonomy, mainCharacters = []) => {
  const pages = createTaxonomyGroups(taxonomy);

  // insert main character page
  const idx = pages.map((page) => page.path).indexOf('handling.navngivet hovedperson');
  pages.splice(idx + 1, 0, {
    path: MAINCHARACTER_MERGE_PATH,
    type: 'MainCharacterPage',
    title: 'Hovedpersoner'
  });

  const stemningsIntro = Object.values(InternalData).filter((n) => n.type === 'PageIntro' && n.path === 'stemning')[0];
  const idx2 = pages.map((page) => page.path).indexOf('fortælleteknik.tempo');

  const getPage = (path) => {
    return pages.filter((page) => page.path === path)[0];
  };

  // ref pages
  // currently only 1 level, avoiding potential circular loops
  pages.forEach((page) => {
    if (page.items) {
      page.items.forEach((el) => {
        if (el.ref) {
          el.page = getPage(el.ref);
        }
      });
    }
  });

  // insert main character any character page
  pages.forEach((page) => {
    mainCharacters.forEach((mainCharacter) => {
      if (page.path.startsWith(MAINCHARACTER_MERGE_PATH + '.' + mainCharacter.systemName)) {
        page.mainCharacter = mainCharacter;
        page.orgPath = page.path.replace('.' + mainCharacter.systemName, '');
      }
    });
  });

  if (stemningsIntro) {
    pages.splice(idx2 + 1, 0, stemningsIntro);
  }
  return pages;
};

/**
 * Will create a new taxonomy merged with main characters
 * The ORG_MAINCHARACTER_PATH will be inserted for every main character
 * @param {Object} taxonomy
 * @param {array} mainCharacters
 */
export const getTaxonomyWithCharacters = (taxonomy, mainCharacters = [], includeRefs = true) => {
  const taxonomyCopy = JSON.parse(JSON.stringify(taxonomy));
  const characterTaxonomy = get(taxonomyCopy, ORG_MAINCHARACTER_PATH);
  if (!characterTaxonomy) {
    return taxonomyCopy;
  }
  const characters = {};
  unset(taxonomyCopy, ORG_MAINCHARACTER_PATH);
  set(taxonomyCopy, MAINCHARACTER_MERGE_PATH, characters);

  if (!includeRefs) {
    unset(taxonomyCopy, 'refs');
  }

  mainCharacters.forEach((character) => {
    characters[character.systemName] = characterTaxonomy;
  });
  return taxonomyCopy;
};

export const getExcludedPaths = (taxonomy, taxonomyGroups) => {
  const result = {};
  const taxMap = getTaxonomyLeavesMap(taxonomy);
  taxonomyGroups.forEach((page) => {
    if (page.mainCharacter) {
      page.mainCharacter.selectedElements.forEach((el) => {
        const element = taxMap[el.id];
        if (element && element.excludedPaths) {
          element.excludedPaths.forEach((pathEnd) => {
            if (page.path.endsWith(pathEnd)) {
              let excludedObj = result[page.path];
              if (!excludedObj) {
                excludedObj = {
                  excluded: true,
                  causedBy: []
                };
                result[page.path] = excludedObj;
              }
              excludedObj.causedBy.push({id: element.id, title: element.title});
            }
          });
        }
      });
    }
  });
  return result;
};

/*
 * Used for instance to find elements to be deleted when selecting
 * element that exclude paths
 */
export const getExcludedPagesForElement = (element, currentPage, pages) => {
  return element.excludedPaths
    .map((pathEnd) => getNearestPageByPostfix(currentPage, pages, pathEnd))
    .filter((page) => page);
};
const getNearestPageByPostfix = (currentPage, pages, pathEnd) => {
  // will look at sibling and ancestor pages
  const pathSplit = currentPage.path.split('.');
  let pathLength = pathSplit.length;
  while (pathLength-- > 0) {
    const path = pathSplit.slice(0, pathLength).join('.');
    for (let i = 0; i < pages.length; i++) {
      const p = pages[i];
      if (p.path.startsWith(path) && p.path.endsWith(pathEnd)) {
        return p;
      }
    }
  }
};
