import React from 'react';
import MaterialData from './MaterialData.component';
import MetaSummaryContainer from '../meta/summary/MetaSummaryContainer.component';

const getRenderedMetadata = (material) => (
  <div className="material-data--metadata">
    <h3>
      {material.title}
      <br />
      <small>af {material.creator}</small>
    </h3>
  </div>
);

const SelectMaterialSummary = ({entry, taxonomyWithCharacters, navigate}) => (
  <MetaSummaryContainer
    selectMaterialStyle={true}
    taxonomy={taxonomyWithCharacters}
    entry={entry}
    navigate={navigate}
    selectedElements={entry.selectedElements ? entry.selectedElements : []}
    mainCharacters={entry.mainCharacters}
    nextpage={false}
  />
);

const SelectMaterial = ({material, entries, taxonomyWithCharacters, isEditor, navigate}) => {
  return (
    <div className="select-material--container" data-cy="select-material-container">
      <div className="row">
        <MaterialData material={material} entries={entries} isEditor={isEditor} renderMetadata={getRenderedMetadata} />
      </div>
      <h4 data-cy="select-material-entry-counter">{entries.length} inddateringer på værket</h4>
      {entries.map((entry) => (
        <SelectMaterialSummary
          entry={entry}
          taxonomyWithCharacters={taxonomyWithCharacters}
          navigate={navigate}
          key={entry._id}
        />
      ))}
    </div>
  );
};

export default SelectMaterial;
