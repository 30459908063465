import React from 'react';
import {useNavigate, useLocation} from 'react-router';

const withRouter = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    return <Component navigate={navigate} location={location} {...props} />;
  };
  return Wrapper;
};

function Footer(props) {
  const {pathname} = props.location;
  const isMetabuggi = pathname.includes('metabuggi');
  return (
    <div
      className={
        'col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12 footer-container' +
        (isMetabuggi ? ' buggi-footer' : '')
      }
    >
      <div className="row footer">
        <div className="col-xs-6 footer-left">
          <div className="logo">
            <a href="https://www.dbc.dk/" target="_blank" rel="noopener noreferrer">
              <img src="/img/logo-footer-transparent.png" alt="DBC" title="DBC" />
            </a>
          </div>
        </div>

        <div className="col-xs-6 contact-info">
          <h4>Kontakt</h4>
          DBC DIGITAL A/S
          <br /> Tempovej 7-11
          <br /> 2750 Ballerup
          <br />{' '}
          <a href="https://kundeservice.dbc.dk/" target="_blank" rel="noopener noreferrer">
            {' '}
            Skriv til kundeservice{' '}
          </a>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Footer);
