import React from 'react';
import PropTypes from 'prop-types';

const EmphasisToString = ({string, pattern}) => {
  const index = string.toLowerCase().indexOf(pattern.toLowerCase());
  if (index >= 0) {
    const start = index > 0 ? string.slice(0, index) : '';
    const end = string.slice(index + pattern.length);
    const match = string.slice(index, index + pattern.length);
    return (
      <span>
        {start}
        <u>{match}</u>
        {end}
      </span>
    );
  }
  return string;
};

const Note = ({notes = []}) =>
  notes.length === 0 ? null : notes.length === 1 ? (
    <p>{notes[0]}</p>
  ) : (
    <ul>
      {notes.map((note) => (
        <li key={note}>{note}</li>
      ))}
    </ul>
  );

export default class MetaInputElement extends React.Component {
  constructor() {
    super();

    this.state = {
      open: false
    };
  }

  showNoteBox = (state) => {
    if (this.state.open !== state) {
      this.setState({open: state});
    }
  };

  render() {
    const hasNote = !!this.props.element.note && this.props.element.note.length > 0;

    const isOpen = this.state.open;
    const isOpenClass = isOpen ? 'isOpen' : '';
    const title = this.props.element.title.replace('¤', '');

    return (
      <div className="meta--element">
        <label htmlFor={this.props.element.id}>
          <input
            className="meta--checkbox"
            data-cy="meta-checkbox"
            onChange={() => this.props.toggle(this.props.element, this.props.parentRef)}
            id={this.props.element.id}
            type="checkbox"
            checked={this.props.selected}
            disabled={this.props.element.status === 'deleted' || this.props.disabled}
          />
          <EmphasisToString string={title} pattern={this.props.filter || ''} />
          {this.props.element.excludedPaths && (
            <span className="ml4" style={{color: 'grey'}}>
              ({`Udelukker ${this.props.element.excludedPaths.length > 1 ? 'kategorierne' : 'kategorien'}: `}
              {this.props.element.excludedPaths.map((path) => `"${path}"`).join(', ')})
            </span>
          )}
        </label>
        {hasNote && (
          <div className={`note--wrap ${isOpenClass}`}>
            <span data-cy={`?-${title}`} onClick={() => this.showNoteBox(true)}>
              ?
            </span>

            <div className="note--box" data-cy={`note-box-${title}`}>
              <div className="note--box--arrow"></div>
              <span onClick={() => this.showNoteBox(false)} data-cy={`close-box-${title}`}>
                ×
              </span>
              <h2>{title}</h2>
              <Note notes={this.props.element.note} />
            </div>

            <div data-cy={`dimmer-${title}`} className="note--dimmer" onClick={() => this.showNoteBox(false)} />
          </div>
        )}
      </div>
    );
  }
}

MetaInputElement.propTypes = {
  element: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired
};
