import React from 'react';

export default {
  'ramme.handlingens tid udtrykt i ord': {
    forceSearch: true,
    searchText: 'Søg perioder',
    suggestionsText: 'Forslag til andre perioder',
    description: () => (
      <p>
        Her vælges den periode handlingen udspiller sig i fx middelalderen eller den kolde krig. Tidsangivelsen
        <b> ”nutiden”</b> bedes udtrykt med årstal på næste side. Tidsangivelsen <b>”fremtiden”</b> bedes udtrykt med
        årstal og/eller med relevante emneord i de øvrige kategorier.
      </p>
    )
  },
  'ramme.handlingens tid udtrykt i tal': {
    forceSearch: true,
    searchText: 'Søg årstal',
    suggestionsText: 'Forslag til andre årstal',
    description: () => <p>Her kan indtastes årti eller århundrede fx 1940-1949 eller 1900-1999.</p>
  },
  'ramme.geografisk sted': {
    type: 'ForslagsBokse',
    searchText: 'Søg geografiske steder',
    suggestionsText: 'Forslag til andre geografiske steder',
    description: () => (
      <p>
        Her kan du angive, hvor handlingen foregår, hvis det har relevans for handlingen. Det er muligt at vælge et
        konkret geografisk sted som land eller by.
      </p>
    )
  },
  'ramme.fiktivt sted': {
    forceSearch: true,
    searchText: 'Søg fiktive steder',
    suggestionsText: 'Forslag til andre fiktive steder',
    description: () => (
      <p>
        Her kan du angive et fiktivt sted, hvis handlingen foregår et sted, der ikke findes i virkeligheden som fx
        Gotham City eller Hogwarts. Det kan også angives at handlingen foregår et fiktivt sted, der ikke er navngivet.
      </p>
    )
  },
  'ramme.miljø': {
    searchText: 'Søg miljøer',
    suggestionsText: 'Forslag til andre miljøer',
    description: (props) => (
      <div>
        <p>Her kan du beskrive de omgivelser, handlingen i bogen udspiller sig i.</p>
        Eksempelvis:
        <ul>
          <li> Sociale miljøer, som fx overklassen, den kreative klasse eller middelklassen</li>
          <li>Fysiske lokaliteter, som fx skoler, klostre eller fængsler</li>
          <li>Brancher, som musikbranchen, medieverdenen eller showbusiness</li>
          <li>Lokaliteter i naturen, som fx prærien, ødemarken eller junglen</li>
        </ul>
        <p>
          Hvis der er tale om et bestemt geografisk sted, som Italien eller Viborg kan du angive det under
          <a className="meta--innerlink" onClick={props.gotoGeoSted}>
            {' '}
            Ramme > Geografisk sted
          </a>
        </p>
      </div>
    )
  },
  'ramme.genre': {
    searchText: 'Søg flere ord for genre og form',
    suggestionsText: 'Forslag til andre genrer',
    description: () => (
      <p>
        Her kan du vælge, hvilken genre og litterær form bogen tilhører. Genre forstås som tekster, der har en række
        indholdsmæssige fællestræk. Eksempler på genre kan være krimi, fantasy etc. Form kan være noveller, romaner,
        digte etc. Se DBCs definitioner ved tryk på knappen
        <span className="note--wrap--special">?</span>
      </p>
    )
  },
  'ramme.univers': {
    suggestionsText: 'Forslag til andre universer',
    description: (props) => (
      <div>
        <p>
          Her kan du beskrive, hvilken type af univers eller virkelighed, bogens handling udspiller sig i - fra det
          realistiske til det overnaturlige. Hvis der er tale om en genre, som krimi eller fantasy, kan du angive det
          under
          <a className="meta--innerlink" onClick={props.gotoGenrePage}>
            {' '}
            Ramme > Genre og form
          </a>
        </p>
      </div>
    )
  },
  'handling.handler om': {
    searchText: 'Søg emner',
    suggestionsText: 'Forslag til andre emner',
    description: () => (
      <div>
        <p>Her beskrives bogens handling eller temaer ved hjælp af emneord.</p>
        <p>
          Emneordene kan være på flere niveauer. Det overordnede tema for handlingen kan fx være døden. Der kan også
          beskrives specifikke begivenheder eller fænomener – som fx 2. verdenskrig. Vær opmærksom på at emneord oftest
          forekommer i flertal.
        </p>
        <p>Overvej om handlingen kan udtrykkes ved hjælp af:</p>
        <ul>
          <li>begreber fx livet, døden</li>
          <li>temaer fx tyveri, hekseforfølgelser</li>
          <li>psykologiske forhold fx kærlighed, ensomhed</li>
          <li>sociale fænomener fx fattigdom, arbejdsløshed</li>
          <li>personrelationer og interaktion fx mor-datter forholdet, mobning</li>
          <li>konkrete begivenheder eller fænomener fx olympiske lege, det arabiske forår</li>
        </ul>
      </div>
    )
  },
  'handling.navngivet hovedperson': {
    searchText: 'Søg hovedpersoner',
    suggestionsText: 'Forslag til andre navngivne hovedpersoner',
    description: () => (
      <div>
        <p>
          Hvis hovedpersonen er bredt kendt og gennemgående for en række titler, kan navnet tildeles som emneord. Fx
          Sherlock Holmes. I visse tilfælde kan navnet på en gruppe være relevant i stedet, fx Justice League.
        </p>
        <p>Det er desuden muligt at angive op til 2 bipersoner, hvis de er væsentlige for handlingen.</p>
        <p>Overvej om det vil gøre en søge- og formidlingsmæssig forskel at have navnet som emneord.</p>
      </div>
    )
  },
  'handling.hovedperson(er) - beskrivelse': {
    forceSearch: true,
    searchText: 'Søg emner',
    suggestionsText: 'Forslag til andre emner',
    description: () => (
      <p>
        Med hovedperson menes der den figur, som handlingen centrerer sig om uanset om det er en “kendt figur” eller ej.
        I tilfælde af, at der er flere personer, der er centrale for handlingen, har du mulighed for at beskrive flere.
      </p>
    )
  },
  'handling.hovedperson(er) - beskrivelse.om hovedpersonen': {
    suggestionsText: '',
    description: () => (
      <p>
        Hovedpersonen kan være et menneske, men det kan også være et overnaturligt væsen som fx en vampyr, et dyr som fx
        et får eller en ting som fx en snurretop. I disse tilfælde kan du angive helt specifikt hvilket væsen, dyr eller
        ting, der er tale om. Vær opmærksom på at emneordene oftest forekommer i flertal.
      </p>
    )
  },
  'handling.hovedperson(er) - beskrivelse.hovedpersonens karaktertræk': {
    suggestionsText: 'Forslag til andre karaktertræk',
    description: () => <p>Her kan du vælge ord, der beskriver hovedpersonens personlighed og måde at være på.</p>
  },
  'handling.hovedperson(er) - beskrivelse.hovedpersonens konflikt': {
    suggestionsText: '',
    description: () => (
      <p>
        Her kan du beskrive, hvilke typer problemer, der driver bogens hovedperson og hvem eller hvad hovedpersonen er i
        konflikt med.
      </p>
    )
  },
  specialTitleText: {},
  specialSuggestionsText: {},
  specialSearchText: {
    'overnaturligt væsen': 'Vælg evt. hvilket overnaturligt væsen',
    dyr: 'Vælg evt. hvilket dyr',
    ting: 'Vælg evt. hvilken ting'
  },
  'fortælleteknik.skrivestil og struktur': {
    suggestionsText: 'Forslag til andet',
    skipAlphabetize: true,
    description: () => <p>Her vælger du ord, der beskriver bogens sprog og handlingens forløb.</p>
  },
  'fortælleteknik.fortællerstemme': {
    type: 'ForslagsBokse',
    suggestionsText: '',
    skipAlphabetize: true,
    description: () => (
      <p>Her kan du beskrive, hvem der fortæller historien i bogen, altså hvilken type af fortællerstemme, der er.</p>
    )
  },
  'fortælleteknik.tempo': {
    type: 'ForslagsBokse',
    suggestionsText: '',
    skipAlphabetize: true,
    description: () => <p>Her har du mulighed for at beskrive med hvilken hastighed, handlingen skrider frem.</p>
  },
  stemning: {
    path: 'stemning',
    type: 'PageIntro',
    title: 'Stemning',
    description: () => (
      <div>
        <p>
          På de følgende sider kan du vælge læseoplevelses- og stemningsbeskrivende ord. Vælg begreber, der udtrykker,
          hvilke stemninger, der er i bogen.
        </p>
        <p>Du bliver præsenteret for 9 forskellige stemningskategorier.</p>
        <p>
          Der kan være kategorier, hvor det ikke vil være relevant at tildele stemningsord. I de tilfælde skal
          kategorien blot springes over.
        </p>
      </div>
    )
  },
  'stemning.positiv': {
    suggestionsText: 'Forslag til andre ord',
    description: () => <p>Stemningen i bogen er bekræftende eller imødekommende. Der er en glædelig stemning.</p>
  },
  'stemning.humoristisk': {
    suggestionsText: 'Forslag til andre ord',
    description: () => <p>Læseoplevelsen karakteriseres ved stemninger, der er humoristiske eller sjove.</p>
  },
  'stemning.romantisk': {
    suggestionsText: 'Forslag til andre ord',
    description: () => <p>Læseoplevelsen karakteriseres ved stemninger, der er romantiske og kærlige.</p>
  },
  'stemning.erotisk': {
    suggestionsText: 'Forslag til andre ord',
    description: () => <p>Læseoplevelsen karakteriseres ved at have seksuelle og begærvækkende undertoner.</p>
  },
  'stemning.dramatisk': {
    suggestionsText: 'Forslag til andre ord',
    description: () => <p>En drastisk, voldsom, foruroligende eller spændende læseoplevelse.</p>
  },
  'stemning.trist': {
    suggestionsText: 'Forslag til andre ord',
    description: () => <p>Læseoplevelsen karakteriseres ved en sørgmodig eller bedrøvet atmosfære.</p>
  },
  'stemning.uhyggelig': {
    suggestionsText: 'Forslag til andre ord',
    description: () => <p>Læseoplevelsen har elementer af noget frygtindgydende og skrækindjagende.</p>
  },
  'stemning.fantasifuld': {
    suggestionsText: 'Forslag til andre ord',
    description: () => <p>Læseoplevelsen er præget af urealistiske og opfindsomme idéer og hændelser.</p>
  },
  'stemning.tankevækkende': {
    suggestionsText: 'Forslag til andre ord',
    description: () => <p>Læseoplevelsen giver anledning til eftertanke og overvejelser.</p>
  }
};
