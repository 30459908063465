import request from './../utils/promiseRequest';
import State from './state';

class Store {
  async getStatus() {
    const user = (
      await request({
        url: '/api/auth/status',
        method: 'get'
      })
    ).body.user;
    State.setState({user: {...user, isPending: false}});
  }

  async assignEmail(email) {
    const user = State.getState().user;
    State.setState({user: {...user, isAssigningEmail: true}});
    try {
      await request({
        url: '/api/auth/assignEmail',
        method: 'get',
        query: {email}
      });
      State.setState({user: {...user, isAssigningEmail: false, email}});
    } catch (e) {
      State.setState({user: {...user, isAssigningEmail: false}});
    }
  }

  async getMaterial(id) {
    State.setState({material: {pending: true}});

    const result = await request({
      url: '/api/getMaterial',
      method: 'get',
      query: {id: id}
    });

    const newState = {
      material: result.body.material || {}
    };
    newState.material.pending = false;
    State.setState(newState);
  }

  async getTaxonomy() {
    State.setState({loadingTaxonomy: true});
    const result = await request({url: '/api/getTaxonomy', method: 'get'});
    State.setState({taxonomy: result.body, loadingTaxonomy: false});
  }

  async submitData() {
    State.setState({submittingData: true});
    const state = State.getState();
    const material = state.material;
    const selectedElements = state.selectedElements.map((element) => {
      return {
        id: element.id,
        score: element.score
      };
    });
    const mainCharacters = state.mainCharacters.map((mainCharacter) => {
      return {
        ...mainCharacter,
        selectedElements: mainCharacter.selectedElements.map((element) => ({
          id: element.id,
          score: element.score,
          parentRef: element.parentRef
        }))
      };
    });
    const _id = state._id || null;
    const sentences = state.sentences;
    const comments = state.comments;

    const result = await request({
      url: '/api/storeMaterial',
      method: 'post',
      body: {
        _id,
        material_id: material.pid,
        material_isbn: material.isbn,
        material_title: material.title,
        material_creator: material.creator,
        selected_elements: selectedElements,
        main_characters: mainCharacters,
        sentences: sentences.result.filter((s) => s.checked),
        comments: comments
      }
    });

    State.setState({submittingData: false});
    // TODO better errorhandling

    return result.body;
  }

  /**
   * Returns a list of the book meta data entered by the given user
   * @param {String} user The user get the book meta data for
   */
  async getMetadataList(fetchAll = false) {
    const result = await request({
      url: '/api/getMetadataList',
      method: 'get',
      query: {fetchAll}
    });

    let newState = fetchAll ? {metadataAll: result.body.metadata} : {metadata: result.body.metadata};

    State.setState(newState);
  }

  async getMetadata(user, material) {
    try {
      const result = await request({
        url: '/api/getMetadata',
        method: 'get',
        query: {
          material_id: material.pid
        }
      });

      const selectedElements = result.body.metadata.selected_elements;
      const mainCharacters = result.body.metadata.main_characters;
      const sentences = result.body.metadata.sentences;
      const comments = result.body.metadata.comments;
      const created = result.body.metadata.created;
      const editedBy = result.body.metadata.editedBy;

      State.setState({
        selectedElements,
        mainCharacters,
        currentIndex: 0,
        sentences: {result: sentences},
        comments,
        editedBy,
        created
      });
    } catch (error) {
      if (error.status === 404) {
        State.setState({
          mainCharacters: [],
          selectedElements: [],
          currentIndex: 0,
          sentences: {result: []},
          comments: {}
        });
      } else {
        throw error;
      }
    }
  }

  async getMetadataAsEditor(material) {
    try {
      const result = await request({
        url: '/api/getMetadataAsEditor',
        method: 'get',
        query: {
          material_id: material.pid
        }
      });

      const dataEntries = result.body.metadata.map((e) => {
        return {
          _id: e._id,
          pid: e.pid,
          selectedElements: e.selected_elements,
          mainCharacters: e.main_characters,
          sentences: {result: e.sentences},
          comments: e.comments,
          created: e.created,
          editedBy: e.editedBy,
          user: e.user
        };
      });

      State.setState({
        dataEntries
      });
    } catch (error) {
      if (error.status === 404) {
        State.setState({
          dataEntries: []
        });
      } else {
        throw error;
      }
    }
  }

  async getBuggiMetadataAsEditor(material) {
    try {
      State.setState({
        buggiEntries: []
      });
      const result = await request({
        url: `/api/buggi/${material.pid}`,
        method: 'get'
      });
      State.setState({
        buggiEntries: [result.body]
      });
    } catch (error) {
      if (error.status === 404) {
        State.setState({
          buggiEntries: []
        });
      } else {
        throw error;
      }
    }
  }

  async getSentences(pid, tagIds) {
    const savedSentences = State.getState().sentences;
    try {
      State.setState({sentences: {fetching: true, error: false, result: []}});
      const result = await request({
        url: '/api/sentences/' + pid,
        method: 'get',
        query: {
          tags: tagIds
        }
      });

      let fullResult = result.body.map((s) => {
        let savedtypes = savedSentences.result.filter((oldS) => oldS.type === s.type && oldS.checked);
        if (savedtypes.length > 0) {
          return savedtypes[0];
        }
        return s;
      });

      State.setState({
        sentences: {fetching: false, error: false, result: fullResult}
      });
    } catch (error) {
      State.setState({sentences: {fetching: false, error, result: []}});
    }
  }

  async getBuggiData() {
    try {
      const result = await request({
        url: '/api/buggi',
        method: 'get'
      });
      State.setState({
        buggidata: result.body.buggidata
      });
    } catch (error) {
      State.setState({
        buggidata: [],
        buggidataError: true
      });
    }
  }
}

export default new Store();
