import React, {useState, useEffect} from 'react';
import Emoji from './Emoji.component';
import BuggiModal from './BuggiModal.component';
const keyToImageSrc = {
  'kort/lang': '/img/buggi/kort-lang.svg',
  'let/svær': '/img/buggi/spytte.svg',
  'tekst/tegninger': '/img/buggi/ramme.svg',
  'virkelig/fantasi': '/img/buggi/mermaid.png'
};

const EmojiInfo = ({name, weight, id = 0}) => {
  if (weight === 0) {
    return;
  }
  const [show, setShow] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 10 * id);
  });
  const displayName = name === 'let/svær' ? 'Sværhedsgrad' : name.charAt(0).toUpperCase() + name.slice(1);
  return (
    <div key={name} className="emoji-info-container">
      <div className="emoji-info-top">
        {name.includes('/') ? (
          <img src={keyToImageSrc[name]} className="Emoji_small" alt={name + '_icon'} />
        ) : (
          <Emoji name={name} />
        )}
        <div className="input-overview-emoji-name">{displayName + ': ' + weight}</div>
      </div>

      <div
        className="emoji-info-bar"
        style={{
          background: '#fffcf3'
        }}
      >
        <div
          style={{
            background: '#d3523b',
            width: show ? `${weight * 20}%` : '0%'
          }}
        ></div>
      </div>
    </div>
  );
};
export default (props) => {
  const {tags, material, saveData, goBack, onDataSaved, renderHeader} = props;
  const [fetchError, setFetchError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [dataSaved, setDataSaved] = useState(false);

  return (
    <React.Fragment>
      <BuggiModal show={dataSaved} handleClose={() => setDataSaved(false)} />

      <div className="buggi-editor-container row">
        <div className="buggi-editor-overview-top">
          <button className="buggi-goback-btn" onClick={goBack}>
            <img src="/img/buggi/back.svg" alt="gå tilbage" />
            Rediger
          </button>
        </div>
        {renderHeader()}
        <div className="buggi-editor-book-info row">
          {material.image && <img src={material.image} alt={`Forside til ${material.title}`} />}
          <h1 data-cy="buggi-editor-material-title">{material.title}</h1>
          <h2> Af {material.creator} </h2>
        </div>
        <div className="input-overview-container ">
          <div className="input-overview-header ">
            <h2>Opsummering</h2>
          </div>
          {tags.map((tag, index) => {
            return EmojiInfo({name: tag.name, weight: tag.value, id: index});
          })}
        </div>
        <button
          data-cy="buggi-submit-btn"
          className="buggi-submit-btn"
          disabled={isSaving}
          onClick={() => {
            setIsSaving(true);
            saveData()
              .then(() => {
                setDataSaved(true);
                setTimeout(() => {
                  onDataSaved();
                }, 5000);
              })
              .catch(() => {
                setFetchError(true);
              })
              .finally(() => {
                setIsSaving(false);
              });
          }}
        >
          {isSaving ? 'Gemmer..' : dataSaved ? 'Gemt' : 'Godkend'}
        </button>
        {fetchError && <p>Noget gik galt. Kunne ikke gemme..</p>}
      </div>
    </React.Fragment>
  );
};
