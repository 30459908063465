import React, {Fragment} from 'react';
import State from '../../store/state';
import Store from '../../store/store';
import MaterialForm from './MaterialForm.component';
import MaterialData from './MaterialData.component';
import MaterialReviewedList from './MaterialReviewedList.component';
import SelectMaterial from './SelectMaterial.component';
import Spinner from '../spinner/Spinner.component';
import {getTaxonomyWithCharacters} from '../../utils/taxonomy.utils';
import moment from 'moment';

const noop = () => {};

const hasMaterial = (material) => material.title || material.creator || material.isbn;

const hasAnyEntries = (pid, entries) =>
  Array.isArray(entries) &&
  entries.length > 0 &&
  entries.reduce((acc, entry) => acc || entry.material_id === pid, false);

const MaterialButton = ({
  className = '',
  dataCy = '',
  btnStyle = 'btn-success',
  onClick = {noop},
  children,
  disabled,
  title
}) => (
  <div className={className}>
    <button
      type="button"
      className={'btn ' + btnStyle}
      data-cy={dataCy}
      onClick={onClick}
      disabled={disabled}
      title={title}
    >
      {children}
    </button>
  </div>
);

const BackHeader = ({selectMaterial, backAction}) => (
  <form>
    {selectMaterial && (
      <MaterialButton
        className="material-container--back-header-button"
        btnStyle=""
        onClick={() => backAction('Inde i BackHeader')}
      >
        <span className="glyphicon glyphicon-arrow-left" /> Tilbage
      </MaterialButton>
    )}
  </form>
);

const LogoutHeader = ({isEditor, isBuggiLektor, isBuggiTagger, isLektor}) => (
  <form action="/api/auth/logout" className="logout-header">
    <span>
      Læsekompas: {isLektor ? 'Lektør' : 'Tagger'}
      {isBuggiLektor ? ' |  Buggi: Lektør' : isBuggiTagger && ' |  Buggi: Tagger'}
      {isEditor && ' | Redaktør'}
    </span>

    <button type="submit" className="btn btn-link pl0" id="logout-btn">
      Log ud
    </button>
  </form>
);

const MaterialContainerHeader = ({selectMaterial, isEditor, isBuggiLektor, isBuggiTagger, isLektor, backAction}) => (
  <div className="material-container--header">
    <LogoutHeader isEditor={isEditor} isBuggiLektor={isBuggiLektor} isBuggiTagger={isBuggiTagger} isLektor={isLektor} />
    <BackHeader selectMaterial={selectMaterial} backAction={backAction} />
    <h1 className="text-center title--thin">Metakompas</h1>
  </div>
);

const EditBox = ({header = '', footer = '', children, className, guideLink, ...props}) => (
  <div {...props} className={`edit-box ${className}`}>
    <div className="edit-box--header">{header}</div>
    <div className="edit-box--content">{children}</div>
    <div className="edit-box--footer">{footer}</div>
    {guideLink}
  </div>
);

const Columns = ({children, ...props}) => (
  <div className="edit-box--columns" {...props}>
    {children}
  </div>
);

const LaesekompasButtons = ({state, createNewEntry, editEntry}) => {
  if (!hasMaterial(state.material)) {
    return null;
  }
  return state.user.isEditor ? (
    <Fragment>
      <MaterialButton btnStyle="btn-success" dataCy="material-data-create-new--editor" onClick={createNewEntry}>
        OPRET NY
      </MaterialButton>
      {hasAnyEntries(state.material.pid, state.metadataAll) && (
        <MaterialButton btnStyle="btn-success" dataCy="material-data-edit--editor" onClick={editEntry}>
          REDIGER
        </MaterialButton>
      )}
    </Fragment>
  ) : (
    <MaterialButton btnStyle="btn-success" dataCy="material-data--non-editor" onClick={createNewEntry}>
      {hasAnyEntries(state.material.pid, state.metadata) ? 'REDIGER' : 'OPRET NY'}
    </MaterialButton>
  );
};

const BuggiButtons = ({state}) => {
  if (!(state.user.isBuggiLektor || state.user.isBuggiTagger)) {
    return null;
  }
  return (
    <Fragment>
      {state.user.isEditor ? (
        <MaterialButton
          dataCy="material-data-buggi--editor"
          btnStyle="btn-primary"
          onClick={() => {
            window.location.href = `/metabuggi/${state.material.pid}`;
          }}
        >
          {(state.buggiEntries && state.buggiEntries.length) > 0 ? 'REDIGER' : 'OPRET NY'}
        </MaterialButton>
      ) : (state.buggiEntries && state.buggiEntries.length) > 0 ? (
        state.user.id === state.buggiEntries[0].user_id && (
          <MaterialButton
            btnStyle="btn-primary"
            dataCy="material-data-buggi-edit"
            onClick={() => {
              window.location.href = `/metabuggi/${state.material.pid}`;
            }}
          >
            REDIGER
          </MaterialButton>
        )
      ) : (
        <MaterialButton
          btnStyle="btn-primary"
          dataCy="material-data-buggi-new"
          onClick={() => {
            window.location.href = `/metabuggi/${state.material.pid}`;
          }}
        >
          OPRET NY
        </MaterialButton>
      )}
    </Fragment>
  );
};

const updateLocale = () =>
  moment.updateLocale('da', {
    months: [
      'januar',
      'februar',
      'marts',
      'april',
      'maj',
      'juni',
      'juli',
      'august',
      'september',
      'oktober',
      'november',
      'december'
    ]
  });

const Created = ({pid, data}) => {
  updateLocale();
  const found = data && data.length > 0 && data.find((item) => (item.pid || item.material_id) === pid);
  return (
    <Fragment>
      <strong>Sidst rettet:</strong>{' '}
      {found ? moment(found.modified || found.created).format('Do MMMM  YYYY') : 'Ikke oprettet'}
    </Fragment>
  );
};

export default class MaterialContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: Object.assign({}, State.getState().user),
      material: Object.assign({}, State.getState().material),
      buggidata: Object.assign([], State.getState().buggidata),
      metadata: Object.assign([], State.getState().metadata),
      metadataAll: Object.assign([], State.getState().metadataAll),
      dataEntries: Object.assign([], State.getState().dataEntries),
      mainCharacters: Object.assign([], State.getState().mainCharacters),
      taxonomy: Object.assign({}, State.getState().taxonomy),
      searchNoResult: '',
      selectMaterial: false,
      created: State.getState().created || false,
      editedBy: State.getState().editedBy || false
    };
  }

  UNSAFE_componentWillMount() {
    Store.getMetadataList(false);
    Store.getMetadataList(true);
    Store.getBuggiData();
    Store.getTaxonomy();
    State.subscribe(this.onGlobalStateChange.bind(this));
    if (hasMaterial(this.state.material)) {
      if (this.state.user.isEditor) {
        Store.getMetadataAsEditor(this.state.material);
      }
      if (this.state.user.isBuggiLektor || this.state.user.isBuggiTagger) {
        Store.getBuggiMetadataAsEditor(this.state.material);
      }
    }
  }

  componentWillUnmount() {
    State.unsubscribe(this.onGlobalStateChange.bind(this));
  }

  onGlobalStateChange({
    user,
    material,
    metadata,
    metadataAll,
    dataEntries,
    taxonomy,
    mainCharacters,
    created,
    editedBy,
    buggiEntries,
    buggidata
  }) {
    if (JSON.stringify(user) !== JSON.stringify(this.state.user)) {
      this.setState({user: Object.assign({}, user)});
    }
    if (JSON.stringify(material) !== JSON.stringify(this.state.material)) {
      this.setState({material: Object.assign({}, material)});
    }
    if (JSON.stringify(metadata) !== JSON.stringify(this.state.metadata)) {
      this.setState({metadata: Object.assign([], metadata)});
    }
    if (JSON.stringify(metadataAll) !== JSON.stringify(this.state.metadataAll)) {
      this.setState({metadataAll: Object.assign([], metadataAll)});
    }
    if (JSON.stringify(dataEntries) !== JSON.stringify(this.state.dataEntries)) {
      this.setState({dataEntries: Object.assign([], dataEntries)});
    }
    if (JSON.stringify(taxonomy) !== JSON.stringify(this.state.taxonomy)) {
      this.setState({taxonomy: Object.assign({}, taxonomy)});
    }
    if (JSON.stringify(mainCharacters) !== JSON.stringify(this.state.mainCharacters)) {
      this.setState({mainCharacters: Object.assign([], mainCharacters)});
    }
    if (created !== this.state.created) {
      this.setState({created: created || false});
    }
    if (editedBy !== this.state.editedBy) {
      this.setState({editedBy: editedBy || false});
    }
    if (JSON.stringify(buggiEntries) !== JSON.stringify(this.state.buggiEntries)) {
      this.setState({buggiEntries: Object.assign([], buggiEntries)});
    }
    if (JSON.stringify(buggidata) !== JSON.stringify(this.state.buggidata)) {
      this.setState({buggidata: Object.assign([], buggidata)});
    }
  }

  async handleMetadataClick(id) {
    this.state.searchNoResult = (
      <div className="h4 no-results">
        Intet resultat fundet for
        <i>
          &nbsp;
          {id}
        </i>
      </div>
    );
    await Store.getMaterial(id);
    const {user, material} = State.getState();

    await Store.getMetadata(user, material);

    if (user.isEditor) {
      await Store.getMetadataAsEditor(material);
    }
    if (user.isBuggiLektor || user.isBuggiTagger) {
      await Store.getBuggiMetadataAsEditor(material);
    }
    window.scrollTo(0, 0);
  }

  createNewEntry = async (e) => {
    e.preventDefault();
    this.props.navigate('/meta');
  };

  getLaeseBuggiData = () => {
    let metaBuggiArray = [];
    if (this.state.metadata.length > 0) {
      this.state.metadata.forEach((entry) => {
        metaBuggiArray.push(entry);
      });
    }
    if (this.state.buggidata.length > 0) {
      this.state.buggidata.forEach((entry) => {
        entry.isBuggi = true;
        metaBuggiArray.push(entry);
      });
    }
    return metaBuggiArray;
  };

  render() {
    const taxonomyWithCharacters = getTaxonomyWithCharacters(this.state.taxonomy, this.state.mainCharacters, true);
    const metaWithBuggiData = this.getLaeseBuggiData();
    return (
      <div className="col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
        <MaterialContainerHeader
          selectMaterial={this.state.selectMaterial}
          isEditor={this.state.user.isEditor}
          isBuggiLektor={this.state.user.isBuggiLektor}
          isBuggiTagger={this.state.user.isBuggiTagger}
          isLektor={!!this.state.user.lektorId}
          backAction={() => this.setState({selectMaterial: false})}
        />

        {this.state.selectMaterial && (
          <SelectMaterial
            material={this.state.material}
            entries={this.state.dataEntries}
            taxonomyWithCharacters={taxonomyWithCharacters}
            navigate={this.props.navigate}
            isEditor={this.state.user.isEditor}
          />
        )}
        {this.state.selectMaterial || (
          <Fragment>
            <MaterialForm onSubmit={this.handleMetadataClick.bind(this)} />
            <div className="material-data--container row row-eq-height">
              <div className="col-md-10 col-sm-9 col-xs-11">
                {this.state.material.pending && <Spinner />}
                {hasMaterial(this.state.material) && <MaterialData material={this.state.material} />}
                {!hasMaterial(this.state.material) && !this.state.material.pending && this.state.searchNoResult}
              </div>
            </div>

            {!this.state.material.pending && hasMaterial(this.state.material) && (
              <div className="material-data--edit-boxes">
                <Columns>
                  <EditBox
                    className="edit-box--laesekompas"
                    header={<img src="/img/LaesekompasLogo.svg" alt="" />}
                    footer={
                      <LaesekompasButtons
                        state={this.state}
                        createNewEntry={this.createNewEntry}
                        editEntry={() => this.setState({selectMaterial: true})}
                      />
                    }
                    guideLink={
                      <a href="/Vejledning.pdf" target="_blank" rel="noopener noreferrer" className="guide-link">
                        Se vejledning
                      </a>
                    }
                  >
                    {this.state.user.isEditor ? (
                      <Fragment>
                        <Created pid={this.state.material.pid} data={this.state.dataEntries} />
                        <div className="material-data--counter" data-cy="material-data-counter">
                          <strong>Antal inddateringer på værk:</strong> {this.state.dataEntries.length}
                        </div>
                      </Fragment>
                    ) : (
                      <Created pid={this.state.material.pid} data={this.state.metadata} />
                    )}
                  </EditBox>

                  <EditBox
                    className="edit-box--buggi"
                    header={<img src="/img/BuggiLogo.svg" alt="" />}
                    footer={<BuggiButtons state={this.state} />}
                    guideLink={
                      <a href="/Buggi-Vejledning.pdf" target="_blank" rel="noopener noreferrer" className="guide-link">
                        Se vejledning
                      </a>
                    }
                  >
                    {(this.state.user.isBuggiLektor || this.state.user.isBuggiTagger) && (
                      <Fragment>
                        <Created pid={this.state.material.pid} data={this.state.buggiEntries} />
                        {this.state.buggiEntries &&
                          this.state.buggiEntries.length > 0 &&
                          this.state.user.id !== this.state.buggiEntries[0].user_id && (
                            <div>
                              <p>
                                Der kan kun inddateres én gang pr. værk
                                <br />
                                Værket er allerede oprettet af en anden bruger
                              </p>
                            </div>
                          )}
                      </Fragment>
                    )}
                  </EditBox>
                </Columns>
              </div>
            )}

            {metaWithBuggiData.length > 0 && (
              <MaterialReviewedList
                className="personal-reviewed-materials"
                title="Tidligere inddateret - dine"
                metadata={metaWithBuggiData}
                onRowClick={this.handleMetadataClick.bind(this)}
              />
            )}

            {this.state.metadata.length === 0 && this.state.metadataAll.length > 0 && (
              <MaterialReviewedList
                className="all-reviewed-materials"
                title="Tidligere inddateret - alle"
                metadata={this.state.metadataAll}
                onRowClick={this.handleMetadataClick.bind(this)}
              />
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

MaterialContainer.propTypes = {};
