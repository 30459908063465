import State from '../../client/store/state';

export const onClickAbort = () => {
  // eslint-disable-next-line no-alert
  if (window.confirm('Siden bliver nulstillet. Vil du fortsætte?') === true) {
    State.setState({
      material: {
        pending: false
      },
      comments: {},
      currentIndex: 0,
      submittingData: false
    });
    window.location.replace('/meta');
  }
};
