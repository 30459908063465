import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {EditedByEditor} from '../meta/summary/MetaSummaryContainer.component.js';

const getRenderedMetadata = (material, editedBy, created) => {
  moment.updateLocale('da', {
    months: [
      'januar',
      'februar',
      'marts',
      'april',
      'maj',
      'juni',
      'juli',
      'august',
      'september',
      'oktober',
      'november',
      'december'
    ]
  });

  return (
    <div className="material-data--metadata">
      <h3>
        {material.title}
        <br />
        <small>af {material.creator}</small>
      </h3>
      {material.subject && (
        <div>
          <span className="material-data--itemname">Emneord:</span>
          <br />
          {material.subject}
        </div>
      )}
      {material.isbn && (
        <div>
          <span className="material-data--itemname">ISBN:</span>
          <br />
          {material.isbn}
        </div>
      )}
      {material.matType && (
        <div>
          <span className="material-data--itemname">Materialetype:</span>
          <br />
          {material.matType}
        </div>
      )}
      {material.pid && (
        <div>
          <span className="material-data--itemname">Pid:</span>
          <br />
          {material.pid}
        </div>
      )}
      {created && (
        <div>
          <span className="material-data--itemname">Sidst rettet:</span>
          <br />
          {moment(created).format('Do MMMM  YYYY')}
          {editedBy && <EditedByEditor />}
        </div>
      )}
    </div>
  );
};

const MaterialData = ({material, editedBy = false, created = false, renderMetadata = getRenderedMetadata}) => (
  <Fragment>
    <span className="material-data--left col-lg-4 col-md-3 col-sm-4 col-xs-3">
      {material.image && <img src={material.image} alt={`Forside til ${material.title}`} />}
    </span>

    <span className="material-data--right col-lg-8 col-md-9 col-sm-8 col-xs-9">
      {renderMetadata(material, editedBy, created)}
    </span>
  </Fragment>
);

MaterialData.propTypes = {
  material: PropTypes.object.isRequired,
  editedBy: PropTypes.bool,
  created: PropTypes.bool,
  renderMetaData: PropTypes.func
};

export default MaterialData;
