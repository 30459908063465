import React from 'react';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emailValue: ''
    };
  }

  onInputChange(e) {
    this.setState({emailValue: e.target.value});
  }

  render() {
    return (
      <div className="input--form">
        <form action="/api/auth/login">
          <button type="submit" className="btn btn-primary btn-block" id="login-btn">
            Log ind
          </button>
        </form>
      </div>
    );
  }
}

export default LoginForm;
