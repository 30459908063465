import React from 'react';
import State from '../../../store/state';
import Store from '../../../store/store';
import {onClickAbort} from '../../../../server/utils/summary.utils';
import MetaInputNavigation from '../MetaInputNavigation.component';
import AddSentencesPage from './AddSentencesPage.component.js';
import {PreviousPageButton, NextPageButton} from '../Pager.component';
import {areAnyObsolete} from './AddSentencesPage.component';
import MetaSummaryContainer from '../summary/MetaSummaryContainer.component';

import {getTaxonomyWithCharacters} from '../../../utils/taxonomy.utils';
import BookPreview from './BookPreview';
export default class AddSentencesContainer extends React.Component {
  constructor() {
    super();

    const {material, selectedElements, sentences, taxonomy, mainCharacters, editedSentences} = State.getState();
    this.state = {
      material,
      selectedElements,
      taxonomy,
      mainCharacters,
      sentences,
      editedSentences
    };
  }

  componentDidMount() {
    State.subscribe(this.onGlobalStateChange);

    let pid = this.state.material.pid;
    let selectedElements = this.state.selectedElements.map((element) => element.id);
    Store.getSentences(pid, selectedElements);
  }

  componentWillUnmount() {
    State.unsubscribe(this.onGlobalStateChange);
  }

  onGlobalStateChange = ({selectedElements, material, sentences}) => {
    this.setState({selectedElements, material, sentences});
  };

  onToggleElement = (e) => {
    let newState = {...State.getState()};
    const letter = e.target.getAttribute('letter');
    const id = e.target.id;
    const sentenceKey = letter === 'A' ? 'aSentence' : 'bSentence';

    const checkedLetter = newState.sentences.result[id].checkedLetter;
    if (checkedLetter === letter && newState.sentences.result[id].checked) {
      newState.editedSentences[sentenceKey] = '';
    }
    if (checkedLetter === 'A' || checkedLetter === 'B') {
      newState.sentences.result.forEach((s) => {
        if (s.checkedLetter === letter) {
          newState.editedSentences[sentenceKey] = '';

          s.checkedLetter = null;
          s.checked = false;
          // break;
        }
      });
      if (checkedLetter === letter) {
        // uncheck
        newState.sentences.result[id].checkedLetter = null;
        newState.sentences.result[id].checked = false;
      } else {
        // check new letter
        newState.sentences.result[id].checkedLetter = letter;
        newState.sentences.result[id].checked = true;
        newState.editedSentences[sentenceKey] =
          newState.sentences.result[id].originalSentence || newState.sentences.result[id].sentence;
      }
    } else {
      // map all the other sentences. if another sentence has the same letter remove it.
      for (const s of newState.sentences.result) {
        if (s.checkedLetter === letter) {
          s.checkedLetter = null;
          s.checked = false;
          break;
        }
      }
      newState.sentences.result[id].checkedLetter = letter;
      newState.sentences.result[id].checked = true;
      if (newState.sentences.result[id].originalSentence) {
        newState.sentences.result[id].sentence = newState.sentences.result[id].originalSentence;
        delete newState.sentences.result[id].originalSentence;
      }
      newState.editedSentences[sentenceKey] = newState.sentences.result[id].sentence;
    }

    State.setState(newState);
  };
  getNewSentences = () => {
    let pid = this.state.material.pid;
    let selectedElements = this.state.selectedElements.map((element) => element.id);
    Store.getSentences(pid, selectedElements);
  };
  onNextPage = (e) => {
    let newState = {...State.getState()};
    let sentenceChecked = false;
    const editedSentences = newState.editedSentences;
    newState.sentences.result.forEach((s, i) => {
      if (s.checked) {
        sentenceChecked = true;
        if (s.checkedLetter === 'A') {
          newState.sentences.result[i] = {
            ...s,
            originalSentence: s.originalSentence ? s.originalSentence : s.sentence,
            sentence: editedSentences.aSentence
          };
        } else if (s.checkedLetter === 'B') {
          newState.sentences.result[i] = {
            ...s,
            originalSentence: s.originalSentence ? s.originalSentence : s.sentence,
            sentence: editedSentences.bSentence
          };
        }
      }
    });
    State.setState(newState);
    if (!sentenceChecked) {
      // eslint-disable-next-line no-alert
      window.alert('Vælg mindst én sætning før du går videre');
      e.preventDefault();
    }
    if (areAnyObsolete(this.state.sentences.result, this.state.selectedElements)) {
      // eslint-disable-next-line no-alert
      window.alert(
        'En eller flere sætninger indeholder tags, der er blevet fjernet og kan derfor ikke bruges til at beskrive bogen.\n' +
          'Vælg en anden sætning for at komme videre.'
      );
      e.preventDefault();
    }
  };
  tagIdToTagName = (tagid = 5630) => {
    const {selectedElements, taxonomy} = this.state;
    let selectedTag;
    let tagname;
    for (let element of selectedElements) {
      if (tagid === element.id) {
        selectedTag = element;
        break;
      }
    }
    if (selectedTag) {
      const searchArr = taxonomy[selectedTag.parents[0]][selectedTag.parents[1]];

      for (let element of searchArr) {
        if (tagid === element.id) {
          tagname = element;
          break;
        }
      }
    }
    return tagname ? tagname.title.replace('¤', '') : 'ukendt tag';
  };

  render() {
    const {sentences, selectedElements, taxonomy, mainCharacters} = this.state;
    const taxonomyWithCharacters = getTaxonomyWithCharacters(taxonomy, mainCharacters);
    const vaildSentences = sentences.result.filter((s) => s.valid);
    const noSentencesGenerated = sentences.fetching ? false : vaildSentences.length === 0;

    return (
      <div className="meta--wrapper mb6">
        <MetaInputNavigation
          currentPageSpecial={'Metakompas'}
          prevPageSpecial={'Rediger indtastning'}
          onNavigation={() => this.props.navigate('/meta')}
          currentIndex={1}
        />

        <div className="meta--container container">
          <div className="col-lg-3 col-md-3 col-sm-4" />
          <div className="col-lg-6 col-md-6 col-sm-4">
            <div>
              <h3>Kort beskrivelse</h3>
              <p className="sentences-intro-text">
                Vælg de to sætninger, der beskriver læseoplevelsen bedst. Du kan tilrette sætningernes konstruktion og
                grammatik.
              </p>

              <AddSentencesPage
                sentences={sentences.result}
                selectedElements={selectedElements}
                onToggle={this.onToggleElement.bind(this)}
                tagIdToTagName={this.tagIdToTagName.bind(this)}
                noSentencesGenerated={noSentencesGenerated}
              />
              {!noSentencesGenerated && (
                <div className="refresh">
                  <button type="button" className="btn btn-outline-primary refreshBtn" onClick={this.getNewSentences}>
                    Vil du have andre forslag? &nbsp;&nbsp;&nbsp;
                    <span className="glyphicon glyphicon-refresh gi-2x" />
                  </button>
                </div>
              )}
            </div>

            <div className="meta--subnav">
              <PreviousPageButton navigateTo="/prioritize" />
              <NextPageButton onClick={this.onNextPage} navigateTo="/confirmation" />
            </div>

            <div>
              <button className="btn btn-link pl0" onClick={onClickAbort}>
                Annullér
              </button>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-4">
            <BookPreview />
            {
              <MetaSummaryContainer
                taxonomy={taxonomyWithCharacters}
                selectedElements={selectedElements}
                mainCharacters={mainCharacters}
                history={this.props.history}
                onAddSentencePage={true}
              />
            }
          </div>
        </div>
      </div>
    );
  }
}
