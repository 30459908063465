import React from 'react';
import PropTypes from 'prop-types';
import InternalData from '../../../InternalData.js';

class CharacterName extends React.Component {
  constructor(props) {
    super(props);
    this.state = {name: props.character.name, editing: false};
  }
  componentDidUpdate(prevProps, prevState) {
    if (!prevState.editing && this.state.editing) {
      this.input.focus();
    }
  }
  render() {
    return (
      <span>
        {this.state.editing && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (this.state.name.trim().length > 0) {
                this.props.onEditCharacterName(this.state.name);
                this.setState({editing: false, error: ''});
              }
            }}
          >
            <span>Navn: </span>{' '}
            <input
              style={{width: '50%'}}
              ref={(input) => (this.input = input)}
              type="text"
              name="name"
              value={this.state.name}
              onChange={(e) => this.setState({name: e.target.value})}
            />
            <input type="submit" className="btn btn-default ml3" value="Gem" />
            {this.state.error && (
              <span style={{color: 'red'}} className="ml3">
                {this.state.error}
              </span>
            )}
          </form>
        )}
        {!this.state.editing && (
          <span
            className="btn btn-link pt0 pb0 pl0 pr0"
            onClick={() => this.props.onCharacterClick(this.props.character.systemName)}
          >
            {this.state.name || 'Navn'}
          </span>
        )}
        {!this.state.editing && (
          <span
            className="glyphicon glyphicon-pencil ml3 btn pt0 pb0 pl0 pr0 mt0"
            style={{fontSize: 10, verticalAlign: 'top'}}
            onClick={() => this.setState({editing: true})}
          />
        )}
      </span>
    );
  }
}
const CharacterList = ({mainCharacters = [], onRemoveCharacter, onEditCharacterName, onCharacterClick}) => {
  return (
    <div className="table-responsive">
      <table className="table table-hover">
        <tbody>
          {mainCharacters.map((character, idx) => {
            return (
              <tr key={idx + character.systemName}>
                <td>{character.systemName}</td>
                <td>
                  <CharacterName
                    character={character}
                    onCharacterClick={onCharacterClick}
                    onEditCharacterName={(name) => {
                      onEditCharacterName(idx, name);
                    }}
                  />
                </td>
                <td className="text-right">
                  <span
                    className="glyphicon glyphicon-remove-circle btn pt0 pb0 pl0 pr0 mt0"
                    onClick={() => {
                      onRemoveCharacter(idx);
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
const MainCharacterPage = (props) => {
  const {onAddCharacter} = props;
  const description = InternalData['handling.hovedperson(er) - beskrivelse'].description(props);
  return (
    <div className="meta--page">
      <h2>Hovedpersoner</h2>
      {description}
      <CharacterList {...props} />

      <div className="text-right mb7">
        <button className="btn btn-default" onClick={onAddCharacter}>
          Tilføj hovedperson
        </button>
      </div>
    </div>
  );
};

MainCharacterPage.propTypes = {
  onAddCharacter: PropTypes.func.isRequired,
  onRemoveCharacter: PropTypes.func.isRequired,
  onEditCharacterName: PropTypes.func.isRequired
};

export default MainCharacterPage;
