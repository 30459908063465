import React from 'react';
const Emoji = ({name, size = 'small', onClick, className = ''}) => {
  const sizeStyle = `Emoji_${size}`;

  return (
    <img
      draggable="false"
      className={`Emoji ${sizeStyle} twemoji ` + className}
      style={onClick ? {cursor: 'pointer'} : {}}
      alt="😂"
      src={`/img/emojis/${name}.svg`}
      onClick={() => {
        if (onClick) {
          onClick(name);
        }
      }}
    />
  );
};

export default Emoji;
