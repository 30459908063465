import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

export default class MaterialReviewedList extends React.Component {
  getRows(atLeastOneBuggi) {
    moment.updateLocale('da', {
      months: [
        'januar',
        'februar',
        'marts',
        'april',
        'maj',
        'juni',
        'juli',
        'august',
        'september',
        'oktober',
        'november',
        'december'
      ]
    });

    return this.props.metadata
      .sort((a, b) => {
        const a_creator = a.material_creator ? a.material_creator : '';
        const b_creator = b.material_creator ? b.material_creator : '';
        return a_creator.localeCompare(b_creator);
      })
      .map((material, index) => {
        return (
          <tr
            key={index}
            data-cy={`entry-${material.material_title}`}
            onClick={() => {
              this.props.onRowClick(material.material_id);
            }}
          >
            {atLeastOneBuggi && (
              <td data-cy={'buggi-img'}>{material.isBuggi && <img alt="" src="img/Buggi_inverted.svg" />}</td>
            )}
            <td>{material.material_title}</td>
            <td>{material.material_creator}</td>
            <td>{material.material_id.split(':')[1]}</td>
            <td>{material.material_isbn}</td>
            <td>{moment(material.modified || material.created).format('Do MMMM  YYYY, HH:mm')}</td>
          </tr>
        );
      });
  }

  render() {
    const atLeastOneBuggi = this.props.metadata.filter((e) => e.isBuggi).length > 0;
    return (
      <div className="row">
        <div className={'material-reviewed--table col-xs-12 ' + this.props.className || ''}>
          <h4>{this.props.title}</h4>
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  {atLeastOneBuggi && <th></th>}
                  <th>Titel</th>
                  <th>Forfatter</th>
                  <th>Faustnummer</th>
                  <th>ISBN</th>
                  <th>Sidst rettet</th>
                </tr>
              </thead>
              <tbody data-cy={'buggi-test'}>{this.getRows(atLeastOneBuggi)}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

MaterialReviewedList.propTypes = {
  metadata: PropTypes.array.isRequired,
  onRowClick: PropTypes.func.isRequired
};
