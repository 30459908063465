import React from 'react';
import State from './../../../store/state';
import Store from '../../../store/store';
import Spinner from '../../spinner/Spinner.component';

export default class SummaryPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submittingData: false
    };
  }

  getSelectedElements(root) {
    return this.props.selectedElements.filter(
      (element) => this.props.taxonomyLeavesMap[element.id].parents[0] === root
    );
  }

  onClickAbort() {
    // eslint-disable-next-line no-alert
    if (window.confirm('Siden bliver nulstillet. Vil du fortsætte?') === true) {
      State.setState({
        material: {
          pending: false
        },
        comments: {},
        currentIndex: 0,
        submittingData: false
      });
      window.location.replace('/');
    }
  }

  async onClickGodkend() {
    this.setState({submittingData: true});
    const response = await Store.submitData();
    this.setState({
      submittingData: false
    });

    if (!response.error) {
      window.location.replace('/thankyou');
    } else {
      console.error(response.msg); // eslint-disable-line no-console
      // eslint-disable-next-line no-alert
      alert(response.msg);
    }
  }

  renderSentences(sentence, key) {
    if (sentence.checked) {
      return <div key={key}>{sentence.sentence}</div>;
    }
  }

  renderSummaryGroup(title, selectedElements) {
    const {mainCharacters = []} = this.props;
    let prioBold = selectedElements.filter((el) => el && el.prio === 'true');
    let prioNotBold = selectedElements.filter((el) => el && el.prio !== 'true');
    let addComma = 'false';
    if (prioNotBold.length > 0) {
      addComma = 'true';
    }

    if (prioBold.length < 1) {
      addComma = 'false';
    }

    function getComma() {
      if (addComma === 'true') {
        return ', ';
      }
      return ' ';
    }

    if (title === 'fortælleteknik') {
      return mainCharacters
        .map((mainCharacter) =>
          this.renderSummaryGroup(
            mainCharacter.systemName + (mainCharacter.name ? ` (${mainCharacter.name})` : ''),
            mainCharacter.selectedElements
          )
        )
        .concat(
          <div key={title} className="summary--page">
            <h3 className="title--thin mb0 capTitle">{title}</h3>
            <span>
              <b>{prioBold.map((item) => item.title.replace('¤', '')).join(', ')}</b>
              {getComma()}
              {prioNotBold.map((item) => item.title.replace('¤', '')).join(', ')}
            </span>
          </div>
        );
    }

    let konfliktElements = [];
    let nonKonfliktElements = [];
    let harKonflikt = false;

    for (let i = 0; i < prioNotBold.length; i++) {
      const t = prioNotBold[i].title.replace('¤', '');
      const p = prioNotBold[i].parents[2];

      if (p === 'hovedpersonens konflikt') {
        konfliktElements.push(t);
        harKonflikt = true;
      } else {
        nonKonfliktElements.push(t);
      }
    }

    return (
      <div key={title} className="summary--page">
        <h3 className="title--thin mb0 capTitle">{title}</h3>
        <span>
          <b>{prioBold.map((item) => item.title.replace('¤', '')).join(', ')}</b>
          {getComma()}
          {nonKonfliktElements.join(', ')}
          {harKonflikt && (
            <div>
              <b style={{fontWeight: '500'}}>I konflikt med: </b>
              {konfliktElements.join(', ')}
            </div>
          )}
        </span>
      </div>
    );
  }

  render() {
    const {sentences} = this.props;
    const pages = Object.keys(this.props.taxonomy);
    return (
      <div className="clearfix">
        <h3>Opsummering</h3>

        <h4 className="">
          {this.props.material.title}

          <small> {this.props.material.creator}</small>
          {this.props.material.subject && (
            <small>
              <br />
              Emneord: {this.props.material.subject}
            </small>
          )}
        </h4>
        <div className="mb4">
          {pages.map((title) => this.renderSummaryGroup(title, this.getSelectedElements(title)))}

          <div key="sentences" className="summary--page">
            <h3 className="title--thin mb0">Sætninger</h3>
            {sentences.map((s, id) => this.renderSentences(s, id))}
          </div>
        </div>
        <div className="mb2">
          <button
            className="btn btn-success btn-block"
            disabled={this.state.submittingData}
            onClick={this.onClickGodkend.bind(this)}
          >
            Godkend {this.state.submittingData && <Spinner />}
          </button>
        </div>
        <div>
          <button className="btn btn-link pl0" onClick={this.onClickAbort.bind(this)}>
            Annullér
          </button>
        </div>
      </div>
    );
  }
}
