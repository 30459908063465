import React from 'react';
import PropTypes from 'prop-types';

class MaterialForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: ''
    };
  }

  trimId(id) {
    const charsToIgnore = id.split(':').length === 1 ? /[-\s]/g : /[\s]/g;
    return id.replace(charsToIgnore, '');
  }

  onSubmit(e) {
    e.preventDefault();
    const inputValue = this.trimId(this.state.inputValue);
    this.setState({inputValue: inputValue});
    this.props.onSubmit(inputValue);
  }

  onInputChange(e) {
    this.setState({inputValue: e.target.value});
  }

  render() {
    return (
      <div className="row">
        <div className="material-input--form">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <label htmlFor="pid-input" />
            <form className="form-inline">
              <div className="form-group material-input--group col-lg-10 col-md-10 col-sm-10 col-xs-10">
                <input
                  type="text"
                  className="form-control material-input--input"
                  id="pid-input"
                  data-cy="pid-input-field"
                  placeholder="Indtast bogens faustnummer eller ISBN"
                  onChange={this.onInputChange.bind(this)}
                  value={this.state.inputValue}
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary col-lg-2 col-md-2 col-sm-2 col-xs-2"
                data-cy="pid-input-button"
                onClick={this.onSubmit.bind(this)}
                disabled={!this.state.inputValue.length}
              >
                Søg
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

MaterialForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default MaterialForm;
