import React from 'react';
import MetaInputElement from './MetaInputElement.component';
import PropTypes from 'prop-types';
import {uniqBy} from 'lodash';
import InternalData from '../../InternalData.js';
import {alphabetize} from '../../utils/taxonomy.utils';
import {get} from 'lodash';

class AddComment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: '',
      showCommentBox: true,
      toggleSave: false
    };
  }

  onFocus() {
    this.setState({toggleSave: true});
  }

  onBlur() {
    this.setState({toggleSave: false});
  }

  render() {
    const {comment = '', placeholderText = '', onChange} = this.props;
    return (
      <div className="mt5">
        <textarea
          className="form-control"
          placeholder={placeholderText}
          type="text"
          value={comment}
          onChange={(e) => onChange(e.target.value)}
          onFocus={() => this.onFocus()}
          onBlur={() => this.onBlur()}
        />
        {this.state.toggleSave && (
          <div className="comment--savebtn">
            <button className="btn btn-success btn-block mb4 text-save">Gem</button>
          </div>
        )}
      </div>
    );
  }
}

class FilterElements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: '',
      showCommentBox: true,
      toggleSave: true
    };
  }

  suggest(filter, elements, maxSuggest, showCountDefault) {
    if (filter.length === 0) {
      return elements.slice(0, showCountDefault);
    }
    return elements
      .filter((element) => element.title.toLowerCase().includes(filter.toLowerCase()))
      .slice(0, maxSuggest);
  }

  isForced(p) {
    let forceSearch = false;
    if (InternalData[p]) {
      forceSearch = InternalData[p].forceSearch;
    }
    return forceSearch;
  }

  showFilter() {
    return this.props.list.length > this.props.minCountForFilter || this.isForced(this.props.path);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.title !== this.props.title) {
      this.setState({filter: ''});
    }
  }

  renderInputElement = (element, selectedIds) => {
    const selected = selectedIds.includes(element.id);

    return (
      <div key={element.id}>
        <MetaInputElement
          element={element}
          key={element.id}
          toggle={this.props.toggleElement}
          filter={this.state.filter}
          selected={selected}
          disabled={this.props.disabled}
          parentRef={this.props.parentRef}
        />

        {element.page && selected && this.props.level === 0 && (
          <div style={{marginLeft: 20}}>
            <FilterElements
              level={this.props.level + 1}
              title={element.title}
              list={element.page.items}
              toggleElement={this.props.toggleElement}
              minCountForFilter="80"
              showCountDefault="0"
              maxSuggest={10}
              selectedElements={this.props.selectedElements}
              disabled={this.props.excluded}
              pages={this.props.pages}
              parentRef={element.id}
            />
          </div>
        )}
      </div>
    );
  };

  render() {
    const {list, selectedElements, maxSuggest = 100, level, showCountDefault, path, orgPath} = this.props;
    const internalDataPath = orgPath || path;
    const {filter} = this.state;
    const listIds = list.map((e) => e.id);
    const selectedIds = selectedElements.map((e) => e.id);
    const sticky = alphabetize(list.filter((e) => e.sticky));
    const showSticky = level === 0 && sticky.length > 0;
    const nonSticky = get(InternalData[internalDataPath], 'skipAlphabetize')
      ? list.filter((e) => !e.sticky)
      : alphabetize(list.filter((e) => !e.sticky));
    const oftenUsed = alphabetize(list.filter((e) => e.oftenUsed));
    const showOftenUsed = level === 0 && oftenUsed.length > 0 && !filter && this.showFilter();
    const suggestions = showOftenUsed
      ? oftenUsed
      : this.showFilter()
      ? this.suggest(filter, nonSticky, maxSuggest, showCountDefault)
      : nonSticky;
    const suggestionIds = suggestions.map((element) => element.id);
    const stickyIds = sticky.map((element) => element.id);
    const selectedRest = alphabetize(
      uniqBy(
        selectedElements.filter(
          (element) =>
            listIds.includes(element.id) && !suggestionIds.includes(element.id) && !stickyIds.includes(element.id)
        ),
        'id'
      )
    );

    let searchText = 'Søg flere ord for ' + this.props.title;

    if (InternalData[internalDataPath]) {
      if (InternalData[internalDataPath].searchText) {
        searchText = InternalData[internalDataPath].searchText;
      }
    }

    if (InternalData.specialSearchText[this.props.title]) {
      searchText = InternalData.specialSearchText[this.props.title];
    }

    let placeholderText = searchText + ' (' + this.props.list.length + ' ord)';

    return (
      <div>
        {showSticky && (
          <div className="sticky-elements mb4">
            {sticky.map((element) => this.renderInputElement(element, selectedIds))}
          </div>
        )}
        {this.showFilter() ? (
          <div className="filter-input">
            <input
              className="form-control mb4"
              placeholder={placeholderText}
              data-cy="filter-input"
              type="text"
              value={this.state.filter}
              onChange={(e) => this.setState({filter: e.target.value})}
            />
            {this.state.filter && (
              <span className="filter-input--clear" onClick={() => this.setState({filter: ''})}>
                x
              </span>
            )}
          </div>
        ) : (
          ''
        )}
        <div className="mv2">
          {showOftenUsed && <h4>Ofte anvendte:</h4>}
          {suggestions.map((element) => this.renderInputElement(element, selectedIds))}
          {suggestions.length === 0 && filter ? (
            <div>
              Der er ikke nogen ord der matcher <i>{filter}</i>
            </div>
          ) : (
            ''
          )}
        </div>
        {selectedRest.length > 0 && (
          <div className="selected-elements-rest mt3 pt3 border-top">
            {selectedRest.map((element) => this.renderInputElement(element, selectedIds))}
          </div>
        )}
      </div>
    );
  }
}

const MetaInputPage = (props) => {
  function getPlaceHolderInfo() {
    let showCommentBox = true;
    let suggestionsText = 'Forslag til nye ord i ' + props.title;
    if (InternalData[props.path]) {
      suggestionsText = InternalData[props.path].suggestionsText;
    }
    if (InternalData[props.orgPath]) {
      suggestionsText = InternalData[props.orgPath].suggestionsText;
    }

    if (InternalData.specialSuggestionsText[props.title]) {
      suggestionsText = InternalData.specialSuggestionsText[props.title];
    }

    if (suggestionsText === '') {
      showCommentBox = false;
    }
    return [showCommentBox, suggestionsText];
  }

  function showTitleText() {
    if (InternalData[props.path] && InternalData[props.path].description) {
      return InternalData[props.path].description(props);
    }
    if (InternalData[props.orgPath] && InternalData[props.orgPath].description) {
      return InternalData[props.orgPath].description(props);
    }
    if (InternalData.specialTitleText[props.title]) {
      return InternalData.specialTitleText[props.title];
    }
  }

  const group = props.items;
  const placeholderInfo = getPlaceHolderInfo();

  return (
    <div className="meta--page" data-cy="meta-page" style={{color: props.excluded && 'grey'}}>
      {props.excluded && (
        <div style={{color: 'black'}}>
          <h2>Denne kategori er eksluderet</h2>
          <span className="mr2">Følgende tilvalg eksluderer denne kategori:</span>
          <strong>{props.excluded.causedBy.map((el) => el.title).join(', ')}</strong>
        </div>
      )}
      <div className="h2 capTitle">{props.title === 'genre' ? 'genre og form' : props.title}</div>
      <div className="meta--description">{showTitleText()}</div>

      <FilterElements
        level={0}
        path={props.path}
        orgPath={props.orgPath}
        title={props.title}
        list={group}
        toggleElement={props.toggleElement}
        minCountForFilter="80"
        showCountDefault="10"
        selectedElements={props.selectedElements}
        disabled={props.excluded}
        pages={props.pages}
      />
      {placeholderInfo[0] && (
        <AddComment
          placeholderText={placeholderInfo[1] + ' (flere forslag separeres med semikolon)'}
          comment={props.comment}
          onChange={props.onCommentChange}
        />
      )}
      {props.title === 'hovedpersonens konflikt' && (
        <div style={{marginTop: '20px', marginBottom: '25px'}}>
          {' '}
          <a className="meta--innerlink" style={{textDecoration: 'underline'}} onClick={props.gotoCharPage}>
            Vil du tilføje flere hovedpersoner?
          </a>
        </div>
      )}
    </div>
  );
};

MetaInputPage.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  toggleElement: PropTypes.func.isRequired,
  onCommentChange: PropTypes.func.isRequired,
  comment: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired
};

export default MetaInputPage;
