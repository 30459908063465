import React from 'react';
import LoginForm from './LoginForm.component';
import EmailPrompt from './EmailPrompt.component';

class LoginContainer extends React.Component {
  render() {
    const user = this.props.user;
    return (
      <div className="login--container container">
        {!user.isAuthenticated && (
          <React.Fragment>
            <h2 className="title--thin text-center">Velkommen til Metakompasset</h2>
            <br />

            <div className="login--description">
              <p>
                Metakompasset anvendes til beskrivelse af læseoplevelsen af skønlitterære værker. Beskrivelserne
                præsenteres i henholdsvis Læsekompasset og Buggi.
              </p>
              <p>Tildeling af metadata til Buggi foretages kun af DBCs konsulenter og andre fagspecialister.</p>
            </div>
            <div className="input--container" key={'login-container'}>
              <LoginForm />
            </div>
          </React.Fragment>
        )}
        {user.isAuthenticated && user.email === null && (
          <React.Fragment>
            <h2 className="title--thin text-center"> Du er nu logget ind</h2>
            <div className="input--container" key={'login-container'}>
              <EmailPrompt className="mt6" />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

LoginContainer.propTypes = {};

export default LoginContainer;
