let App;
switch (process.env.REACT_APP_WHICH_APP) {
  case 'accounting':
    App = require('./Accounting').default;
    break;
  default:
    App = require('./Metakompas').default;
    break;
}
export default App;
