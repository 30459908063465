import React from 'react';
import State from '../../store/state';

export default function ThankyouContainer() {
  return (
    <div className="thankyou-container">
      <div className="thankyou text-center container">
        <h2>Mange tak for din indsats</h2>
        <p className="thankyou--p">
          <a
            className="btn btn-success"
            href="/"
            onClick={() =>
              State.setState({
                material: {
                  pending: false
                },
                comments: {},
                currentIndex: 0,
                submittingData: false
              })
            }
          >
            Vil du beskrive flere titler? <span className="glyphicon glyphicon-arrow-right" />
          </a>
        </p>
      </div>
    </div>
  );
}
